import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Link } from "react-router-dom";
import AOS from "aos";
import ReactAnime from 'react-animejs'
const {Anime, stagger} = ReactAnime;


import './style.css';
import arrowdown from '../arrowdown.png';

class FirstSite extends React.Component {
  componentDidMount() {
    AOS.init({
      duration : 700,
      delay: 200
    });
  }
  render() {
    return(
      <section class="firstSite">
        <h1>javascript coding</h1>
        <p>All personal projects with source code!</p>

        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 902.418 826.207" class="rocketSvg">
          <g id="Gruppe_5" data-name="Gruppe 5" transform="translate(0 0)">
            <path id="Pfad_29" data-name="Pfad 29" d="M726.95,696.708l8.672,19.444c-7.049-1.122-13.6,4.9-13.751,12.629s6.14,15.774,13.189,16.873c-1.347,6-7.161,8.649-12.207,11.075s-10.485,6.45-10.11,12.845c.3,5.135,4.548,9.669,9.058,11.492s9.241,1.543,13.809,1.237c-6.921,7.24-7.6,20.848-1.517,30.611s18.217,14.569,27.305,10.811c-6.422,7.989-5.027,22.618,2.943,30.862,8.034,8.311,19.678,9.115,29.857,8.24a132.239,132.239,0,0,0,71.007-28.14c7.782-6.183,15.05-13.456,19.49-22.941s5.747-21.5,1.612-32.29c1.115,13.522,14.65,24.2,26.826,24.8s22.807-6.26,31.841-14.081c12.014-10.4,23.137-24.979,21.488-42.967-.816-8.9-4.706-17.469-8.521-25.765l-7.4-16.083c-2.986-6.493-6.039-13.1-10.533-18.544s-10.731-9.667-16.973-9.626c1.427-6.52,2.861-13.283,1.665-20.211s-5.776-14.1-12-15.723c-7.927-2.065-14.645,5.25-22.616,4.561-7.671-.664-14.01-8.362-21.459-11.412a17.178,17.178,0,0,0-18.537,3.206c-4.722,4.61-6.69,12.4-5,19.767,1.484,6.455,5.435,12.149,7.832,18.375s2.77,14.231-1.835,17.631c-5.557,4.1-14.014-1.48-18.356-8.235s-6.834-14.933-12.324-20.614c-6.479-6.7-16.621-8.276-23.293-3.608s-9.222,15.119-5.859,24c2.75,7.264,9.049,14.614,6.864,21.454-2.309,7.23-12.413,6.314-18.507,1.2s-10.141-12.806-16.161-18.03c-6.114-5.306-14.1-7.63-21.107-6.146a21.73,21.73,0,0,0-15.573,13.915" transform="translate(-148.791 -36.897)" fill="#e6e6e6"/>
            <path id="Pfad_30" data-name="Pfad 30" d="M867.347,642.066l-17.993,8.36a13.812,13.812,0,0,1-8.734,1.177c-2.612-.618-4.377-2.215-4.841-4.383l-4.187-19.554c-.791-3.689,2.265-8.053,7.264-10.376l9.937-4.617c5-2.323,10.3-1.843,12.614,1.14l12.244,15.81c1.358,1.753,1.44,4.132.228,6.527A13.812,13.812,0,0,1,867.347,642.066Z" transform="translate(-148.791 -36.897)" fill="#3f3d56"/>
            <path id="Pfad_31" data-name="Pfad 31" d="M309.43,280.659a354,354,0,0,1,77.5-124.057c29.006-30.112,74.15-28.86,74.15-28.86l.258-.32a87.58,87.58,0,0,1,92.553-28.912c4.3,1.235,7.615,1.783,9.189,1.232,28.707-10.047,27.358-32.724,52.126-50.993C658.575,16.762,802.419,53.4,822.7,112.262c16.439,47.7,89.82,70.827,117.236,107.317q6.431,8.559,12.618,17.316,2.9,4.068,5.737,8.2a730.433,730.433,0,0,1,52.147,88.155,514.112,514.112,0,0,1,26.1,61.685c28.591,83.582,18.814,145.855-60.021,130.47q-14-2.739-27.324-4.9-19.3-3.149-37.246-5.176-24.172-2.749-45.994-3.6-7.337-.3-14.405-.4c-69.67-.966-121.349,12.147-160.719,32.5q-10.011,5.163-18.973,10.923a236.051,236.051,0,0,0-26.08,19.319,254.9,254.9,0,0,0-31.423,32.2l-.522.639c-46.048,56.326-61.2,118.682-93.234,129.661C383.674,783.51,297.074,613.35,288.193,446.9q-.673-12.582-.743-25.091-.042-5.592.055-11.154c.141-9.673.567-19.285,1.281-28.78q.4-5.445.928-10.833c.388-3.873.81-7.724,1.3-11.55C294.495,331.442,300.589,304.758,309.43,280.659Z" transform="translate(-148.791 -36.897)" fill="#6c63ff"/>
            <path id="Pfad_32" data-name="Pfad 32" d="M733.834,372.24c-10.935,5.091-11.115,27.895-7.686,35.284l6.214,13.374,39.609-18.4-6.214-13.374C762.321,381.735,744.777,367.166,733.834,372.24Z" transform="translate(-148.791 -36.897)" fill="#3f3d56"/>
            <path id="Pfad_33" data-name="Pfad 33" d="M627.393,564.256a4.331,4.331,0,0,1-1.827-8.26L830.12,460.958a4.33,4.33,0,1,1,3.65,7.854h0L629.215,563.85a4.306,4.306,0,0,1-1.822.406Z" transform="translate(-148.791 -36.897)" fill="#3f3d56"/>
            <rect id="Rechteck_12" data-name="Rechteck 12" width="64.474" height="217.68" transform="translate(580.143 401.293) rotate(-24.92)" fill="#e6e6e6"/>
            <path id="Pfad_34" data-name="Pfad 34" d="M567.9,162.514c-10.935,5.091-11.115,27.9-7.686,35.284l6.214,13.374,39.609-18.4L599.827,179.4C596.392,172.009,578.848,157.44,567.9,162.514Z" transform="translate(-148.791 -36.897)" fill="#3f3d56"/>
            <path id="Pfad_35" data-name="Pfad 35" d="M812.919,664.761l-17.993,8.36a13.812,13.812,0,0,1-8.734,1.177c-2.612-.618-4.377-2.215-4.841-4.383l-4.187-19.554c-.79-3.689,2.265-8.053,7.264-10.376l9.937-4.617c5-2.323,10.3-1.843,12.614,1.14l12.244,15.81c1.358,1.753,1.44,4.132.228,6.527A13.812,13.812,0,0,1,812.919,664.761Z" transform="translate(-148.791 -36.897)" fill="#3f3d56"/>
            <path id="Pfad_36" data-name="Pfad 36" d="M757.6,692.207l-17.993,8.36a13.812,13.812,0,0,1-8.734,1.177c-2.612-.618-4.377-2.215-4.841-4.383l-4.187-19.554c-.791-3.689,2.265-8.053,7.264-10.376l9.937-4.617c5-2.323,10.3-1.843,12.614,1.14l12.244,15.81c1.358,1.753,1.44,4.132.228,6.527a13.812,13.812,0,0,1-6.532,5.916Z" transform="translate(-148.791 -36.897)" fill="#3f3d56"/>
            <rect id="Rechteck_13" data-name="Rechteck 13" width="64.474" height="440.016" transform="translate(429.815 225.147) rotate(-24.92)" fill="#e6e6e6"/>
            <path id="Pfad_37" data-name="Pfad 37" d="M548.951,403.691l-6.561-14.437,3.777-1.742,6.541,14.391,94.715,210.006-3.773,1.753" fill="#3f3d56"/>
            <path id="Pfad_38" data-name="Pfad 38" d="M622.951,391.691l-6.561-14.437,3.777-1.742,6.541,14.391,94.715,210.006-3.773,1.753" fill="#3f3d56"/>
            <path id="Pfad_39" data-name="Pfad 39" d="M664.671,266.814l-79.226,36.809c-5.044-7.847-20.249-48.06-16.835-59.991l-2.563-11.378,54.7-25.416,7.322,9.007C638.942,219.18,660.1,256.951,664.671,266.814Z" transform="translate(-148.791 -36.897)" fill="#e6e6e6"/>
            <path id="Pfad_40" data-name="Pfad 40" d="M623.037,207.325l-56.451,26.228c-2.084.968-4.3.612-4.956-.795a1.95,1.95,0,0,1-.172-.643l-2.922-31.168c-.125-1.335,1.017-2.847,2.761-3.658L598.734,179.9c1.744-.81,3.637-.708,4.576.249l21.936,22.334c1.122,1.142.513,3.1-1.361,4.367a6.075,6.075,0,0,1-.848.479Z" transform="translate(-148.791 -36.897)" fill="#e6e6e6"/>
            <path id="Pfad_41" data-name="Pfad 41" d="M667.313,451.514a7.626,7.626,0,0,1-1.814-.214,5.6,5.6,0,0,1-3.9-3.025L627.09,373.992c-1.428-3.075.489-7.008,4.274-8.767l50.536-23.48c3.786-1.76,8.027-.687,9.457,2.387l34.513,74.283a5.6,5.6,0,0,1-.2,4.929,8.507,8.507,0,0,1-4.074,3.838l-50.536,23.479A8.919,8.919,0,0,1,667.313,451.514Z" transform="translate(-148.791 -36.897)" fill="#3f3d56"/>
            <rect id="Rechteck_14" data-name="Rechteck 14" width="64.474" height="217.68" transform="translate(466.968 453.027) rotate(-24.92)" fill="#e6e6e6"/>
            <path id="Pfad_42" data-name="Pfad 42" d="M602.189,210.581l-17.147,7.967c-3.309,1.537-7.773-1.024-9.95-5.71s-1.256-9.749,2.053-11.286l17.147-7.967c3.309-1.537,7.773,1.024,9.95,5.71S605.5,209.044,602.189,210.581Z" transform="translate(-148.791 -36.897)" fill="#3f3d56"/>
            <path id="Pfad_43" data-name="Pfad 43" d="M621.8,423.444c-10.935,5.091-11.115,27.895-7.686,35.284l6.214,13.374,39.609-18.4-6.214-13.374C650.286,432.939,632.742,418.37,621.8,423.444Z" transform="translate(-148.791 -36.897)" fill="#3f3d56"/>
            <path id="Pfad_44" data-name="Pfad 44" d="M440.152,377.925c-3.862,2.573-7.815-3.551-3.878-6.01C440.135,369.342,444.088,375.466,440.152,377.925Z" transform="translate(-148.791 -36.897)" fill="#e6e6e6"/>
            <path id="Pfad_45" data-name="Pfad 45" d="M842.152,289.925c-3.862,2.573-7.815-3.551-3.878-6.01C842.135,281.342,846.088,287.466,842.152,289.925Z" transform="translate(-148.791 -36.897)" fill="#e6e6e6"/>
            <path id="Pfad_46" data-name="Pfad 46" d="M568.152,359.925c-3.862,2.573-7.815-3.551-3.878-6.01C568.135,351.342,572.088,357.466,568.152,359.925Z" transform="translate(-148.791 -36.897)" fill="#e6e6e6"/>
            <path id="Pfad_47" data-name="Pfad 47" d="M440.152,599.925c-3.862,2.573-7.815-3.551-3.878-6.01C440.135,591.342,444.088,597.466,440.152,599.925Z" transform="translate(-148.791 -36.897)" fill="#e6e6e6"/>
            <path id="Pfad_48" data-name="Pfad 48" d="M637.152,98.925c-3.862,2.573-7.815-3.551-3.878-6.01C637.135,90.342,641.088,96.466,637.152,98.925Z" transform="translate(-148.791 -36.897)" fill="#e6e6e6"/>
            <path id="Pfad_49" data-name="Pfad 49" d="M447.152,216.925c-3.862,2.573-7.815-3.551-3.878-6.01C447.135,208.342,451.088,214.466,447.152,216.925Z" transform="translate(-148.791 -36.897)" fill="#e6e6e6"/>
            <path id="Pfad_50" data-name="Pfad 50" d="M571.639,146.2l-2.457-.539.538-2.458-1.638-.359-.539,2.458-2.457-.538-.359,1.638,2.457.539-.538,2.457,1.638.359.539-2.458,2.457.539Z" fill="#e6e6e6"/>
            <path id="Pfad_51" data-name="Pfad 51" d="M171.542,350.978l-2.457-.539.538-2.457-1.638-.359-.539,2.457-2.458-.538-.359,1.638,2.458.539-.538,2.458,1.638.358.539-2.457,2.457.538Z" fill="#e6e6e6"/>
            <path id="Pfad_52" data-name="Pfad 52" d="M824.22,492.333a8.51,8.51,0,0,1-11.29-4.127L802.817,466.44a8.5,8.5,0,0,1,15.417-7.163l10.113,21.765A8.51,8.51,0,0,1,824.22,492.333Z" transform="translate(-148.791 -36.897)" fill="#3f3d56"/>
            <path id="Pfad_53" data-name="Pfad 53" d="M652.22,572.333a8.51,8.51,0,0,1-11.29-4.127L630.817,546.44a8.5,8.5,0,1,1,15.417-7.163l10.113,21.766a8.51,8.51,0,0,1-4.127,11.29Z" transform="translate(-148.791 -36.897)" fill="#3f3d56"/>
            <path id="Pfad_54" data-name="Pfad 54" d="M808.642,444.778l-67.806,31.5c-4.317-6.716-17.331-41.133-14.409-51.344l1.434-11.423,40.486-19.863,8.971,7.505C789.959,396.883,808.539,428.2,808.642,444.778Z" transform="translate(-148.791 -36.897)" fill="#e6e6e6"/>
            <path id="Pfad_55" data-name="Pfad 55" d="M777.162,442.594l-17.147,7.967c-3.309,1.537-8.344-2.253-11.222-8.447s-2.528-12.487.781-14.024l17.147-7.967c3.309-1.537,8.344,2.253,11.222,8.447S780.471,441.056,777.162,442.594Z" transform="translate(-148.791 -36.897)" fill="#3f3d56"/>
            <path id="Pfad_56" data-name="Pfad 56" d="M482.951,446.691l-6.561-14.437,3.777-1.742,6.541,14.391,94.715,210.006-3.773,1.753" fill="#3f3d56"/>
            <path id="Pfad_57" data-name="Pfad 57" d="M696.607,495.981l-67.806,31.5c-10-5.165-25.291-38.961-14.409-51.344l1.434-11.423,40.486-19.863,8.971,7.505C674.587,455.214,692.7,487.541,696.607,495.981Z" transform="translate(-148.791 -36.897)" fill="#e6e6e6"/>
            <path id="Pfad_58" data-name="Pfad 58" d="M663.732,490.793l-17.147,7.967c-3.309,1.537-8.03-1.579-10.524-6.945s-1.83-10.985,1.479-12.522l17.147-7.967c3.309-1.537,8.03,1.579,10.524,6.945S667.041,489.256,663.732,490.793Z" transform="translate(-148.791 -36.897)" fill="#3f3d56"/>
            <circle id="Ellipse_6" data-name="Ellipse 6" cx="103" cy="103" r="103" transform="translate(68.291 101.845)" fill="#ff6584"/>
            <path id="Pfad_59" data-name="Pfad 59" d="M742.4,349.021l62.593-182.809L736.258,346.832a3.442,3.442,0,1,0,6.138,2.188Z" transform="translate(-148.791 -36.897)" fill="#f0f0f0" opacity="0.3"/>
            <path id="Pfad_60" data-name="Pfad 60" d="M901.95,240.055q-18.38,6.108-40.751,12c-77.145,20.311-180.55,36.154-291.166,44.613s-215.227,8.519-294.564.174c-38.647-4.066-69.215-9.913-90.854-17.381-22.909-7.905-34.947-17.432-35.78-28.314-1.621-21.2,40.213-39.859,75.592-51.778l.958,2.843c-48.585,16.369-74.709,33.667-73.558,48.707,1.464,19.156,46.644,34.806,123.955,42.94,79.162,8.327,183.581,8.263,294.021-.181s213.655-24.257,290.63-44.523c75.174-19.791,117.451-42.129,115.986-61.285-1.216-15.9-32.567-29.51-88.278-38.312l.468-2.963c40.48,6.4,89.093,18.708,90.8,41.047.832,10.882-9.618,22.127-31.059,33.424C936.131,227.5,920.6,233.856,901.95,240.055Z" transform="translate(-148.791 -36.897)" fill="#3f3d56"/>
            <circle id="Ellipse_7" data-name="Ellipse 7" cx="10.702" cy="10.702" r="10.702" transform="translate(772.723 364.434)" fill="#ff6584"/>
            <circle id="Ellipse_8" data-name="Ellipse 8" cx="10.702" cy="10.702" r="10.702" transform="translate(840.723 654.434)" fill="#f2f2f2"/>
            <circle id="Ellipse_9" data-name="Ellipse 9" cx="20.866" cy="20.866" r="20.866" transform="translate(465.559 699.27)" fill="#f2f2f2"/>
            <circle id="Ellipse_10" data-name="Ellipse 10" cx="10.702" cy="10.702" r="10.702" transform="translate(316.723 443.434)" fill="#3f3d56"/>
            <path id="Pfad_61" data-name="Pfad 61" d="M687.226,478.768,733.4,490.721l28.321,55.628-47.88,1.878Z" fill="#e6e6e6"/>
            <path id="Pfad_62" data-name="Pfad 62" d="M580.645,398.574,606.059,424l64.547,139.555-11.884,10.267Z" fill="#ccc"/>
            <path id="Pfad_63" data-name="Pfad 63" d="M525.386,421.624l1.994,35.9L588.3,598.694l15.576-2.006Z" fill="#ccc"/>
            <path id="Pfad_64" data-name="Pfad 64" d="M523.723,566.637l-24.869,40.7,18.333,59.669L552.9,635.061Z" fill="#e6e6e6"/>
            <path id="Pfad_65" data-name="Pfad 65" d="M892.2,628.544q-19.368,0-42.457-1.464C770.125,622.024,667,604.447,559.363,577.588s-206.93-59.794-279.585-92.734c-35.393-16.047-62.556-31.236-80.735-45.147-19.247-14.727-27.666-27.564-25.024-38.153,5.148-20.629,50.731-25.142,88.064-25.3l.013,3c-51.268.21-81.514,8.387-85.166,23.022-4.652,18.64,33.287,47.741,104.087,79.841,72.5,32.868,171.605,65.739,279.073,92.557s210.4,44.363,289.84,49.408c77.579,4.928,124.743-2.937,129.395-21.578,3.862-15.477-21.6-38.275-71.689-64.2l1.379-2.664c36.4,18.836,78.646,45.852,73.222,67.589-2.643,10.589-16.105,17.965-40.015,21.923C928.591,627.413,911.85,628.544,892.2,628.544Z" transform="translate(-148.791 -36.897)" fill="#3f3d56"/>
          </g>
        </svg>


        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 413.042 285.903" class="editorSvg">
          <g id="editorSvg" data-name="Gruppe 2" transform="translate(-1413.03 -572.829)">
            <path id="Pfad_12" data-name="Pfad 12" d="M792.262,439.557H399.583c-5.581-.007-10.1-4.863-10.111-10.856V168.717a7.7,7.7,0,0,1,7.407-7.953H794.837a7.837,7.837,0,0,1,7.535,8.091V428.7C802.366,434.694,797.843,439.55,792.262,439.557Z" transform="translate(1023.7 419.175)" fill="#f2f2f2"/>
            <path id="Pfad_13" data-name="Pfad 13" d="M788.664,437.108H422.7a9.767,9.767,0,0,1-9.729-9.782v-226.4a7.241,7.241,0,0,1,7.209-7.255H791.065a7.36,7.36,0,0,1,7.329,7.374V427.326A9.767,9.767,0,0,1,788.664,437.108Z" transform="translate(1014.601 407.918)" fill="#fff"/>
            <path id="Pfad_14" data-name="Pfad 14" d="M802.142,166.373h-412.9v-5.527c.008-6.013,4.547-10.884,10.147-10.889H791.995c5.6,0,10.139,4.876,10.147,10.889Z" transform="translate(1023.789 422.871)" fill="#3f3d56"/>
            <ellipse id="Ellipse_3" data-name="Ellipse 3" cx="2.625" cy="2.818" rx="2.625" ry="2.818" transform="translate(1428.846 578.235)" fill="#fff"/>
            <ellipse id="Ellipse_4" data-name="Ellipse 4" cx="2.625" cy="2.818" rx="2.625" ry="2.818" transform="translate(1438.809 578.235)" fill="#fff"/>
            <ellipse id="Ellipse_5" data-name="Ellipse 5" cx="2.625" cy="2.818" rx="2.625" ry="2.818" transform="translate(1448.772 578.235)" fill="#fff"/>
            <path id="Pfad_15" data-name="Pfad 15" d="M665.524,264.42H593.949a4.331,4.331,0,0,1,0-8.64h71.575a4.331,4.331,0,0,1,0,8.64Z" transform="translate(946.076 386.673)" fill="#ccc"/>
            <path id="Pfad_16" data-name="Pfad 16" d="M810.67,264.42H739.1a4.331,4.331,0,0,1,0-8.64H810.67a4.331,4.331,0,0,1,0,8.64Z" transform="translate(889.869 386.673)" fill="#ccc"/>
            <path id="Pfad_17" data-name="Pfad 17" d="M777.214,289.3H652.7a4.331,4.331,0,0,1,0-8.64H777.214a4.331,4.331,0,0,1,0,8.64Z" transform="translate(923.326 378.162)" fill="#ccc"/>
            <path id="Pfad_18" data-name="Pfad 18" d="M665.524,239.192H593.949a4.331,4.331,0,0,1,0-8.64h71.575a4.331,4.331,0,0,1,0,8.64Z" transform="translate(946.076 395.303)" fill="#ccc"/>
            <path id="Pfad_19" data-name="Pfad 19" d="M762.189,239.538H741.86a4.331,4.331,0,0,1,0-8.64h20.329a4.331,4.331,0,0,1,0,8.64Z" transform="translate(888.799 395.184)" fill="#ccc"/>
            <path id="Pfad_20" data-name="Pfad 20" d="M614.278,289.3H593.949a4.331,4.331,0,0,1,0-8.64h20.329a4.331,4.331,0,0,1,0,8.64Z" transform="translate(946.076 378.162)" fill="#ccc"/>
            <path id="Pfad_21" data-name="Pfad 21" d="M665.524,497.42H593.949a4.331,4.331,0,0,1,0-8.64h71.575a4.331,4.331,0,0,1,0,8.64Z" transform="translate(946.076 306.971)" fill="#ccc"/>
            <path id="Pfad_22" data-name="Pfad 22" d="M810.67,497.42H739.1a4.331,4.331,0,0,1,0-8.64H810.67a4.331,4.331,0,0,1,0,8.64Z" transform="translate(889.869 306.971)" fill="#ccc"/>
            <path id="Pfad_23" data-name="Pfad 23" d="M777.214,522.3H652.7a4.331,4.331,0,0,1,0-8.64H777.214a4.331,4.331,0,0,1,0,8.64Z" transform="translate(923.326 298.46)" fill="#ccc"/>
            <path id="Pfad_24" data-name="Pfad 24" d="M665.524,472.192H593.949a4.331,4.331,0,0,1,0-8.64h71.575a4.331,4.331,0,0,1,0,8.64Z" transform="translate(946.076 315.601)" fill="#ccc"/>
            <path id="Pfad_25" data-name="Pfad 25" d="M762.189,472.538H741.86a4.331,4.331,0,0,1,0-8.64h20.329a4.331,4.331,0,0,1,0,8.64Z" transform="translate(888.799 315.483)" fill="#ccc"/>
            <path id="Pfad_26" data-name="Pfad 26" d="M614.278,522.3H593.949a4.331,4.331,0,0,1,0-8.64h20.329a4.331,4.331,0,0,1,0,8.64Z" transform="translate(946.076 298.46)" fill="#ccc"/>
            <path id="Pfad_27" data-name="Pfad 27" d="M662.171,395.482a5.562,5.562,0,0,1-4.059-1.783l-26.905-28.527a6.338,6.338,0,0,1,0-8.568L658,328.191a5.659,5.659,0,0,1,8.36.028h0a6.712,6.712,0,0,1,0,9L646.452,358.6a3.75,3.75,0,0,0,0,5.031l19.8,21.265a6.534,6.534,0,0,1,1.255,6.758,5.782,5.782,0,0,1-5.34,3.824Z" transform="translate(930.736 362.53)" fill="#6c63ff"/>
            <path id="Pfad_28" data-name="Pfad 28" d="M749.011,395.482a5.782,5.782,0,0,1-5.339-3.824,6.534,6.534,0,0,1,1.254-6.758l19.8-21.264a3.75,3.75,0,0,0,0-5.031l-19.912-21.381a6.7,6.7,0,0,1,0-9h0a5.659,5.659,0,0,1,8.36-.028l26.8,28.412a6.338,6.338,0,0,1,0,8.568L753.07,393.7A5.563,5.563,0,0,1,749.011,395.482Z" transform="translate(886.768 362.53)" fill="#6c63ff"/>
          </g>
        </svg>


        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 850.538 740.83" class="coderSvg">
          <g id="Gruppe_5" data-name="Gruppe 5" transform="translate(0 0)">
            <path id="Pfad_29" data-name="Pfad 29" d="M941.609,465.125a354.21,354.21,0,0,1-98.85,245.9q-5.745,6.015-11.8,11.74-9.225,8.775-19.07,16.87a353.934,353.934,0,0,1-217.58,80.69q-3.975.09-7.98.09c-63.43,0-143.21-33.88-215.61-80.78q-8.985-5.82-17.81-11.91-4.125-2.82-8.21-5.72a730.432,730.432,0,0,1-79.32-64.8,514.12,514.12,0,0,1-45.75-48.92c-55.63-68.62-67.9-130.45,11.41-143.16q14.085-2.25,27.34-4.81,19.2-3.69,36.75-7.97,23.64-5.745,44.42-12.46,6.99-2.25,13.66-4.59c65.74-23.09,109.74-53.2,139.69-85.87q7.62-8.295,14.05-16.79a236.062,236.062,0,0,0,17.83-27.12,254.9,254.9,0,0,0,18.41-41.05l.27-.78c23.83-68.74,16.58-132.5,42.87-153.84,112.38-91.23,252.29,38.69,317.96,191.9q4.965,11.58,9.34,23.3,1.965,5.235,3.79,10.49,4.8,13.695,8.71,27.46,1.5,5.25,2.86,10.49c.97,3.77,1.9,7.53,2.76,11.29C938.139,412.3,941.609,439.455,941.609,465.125Z" transform="translate(-174.731 -79.585)" fill="#f2f2f2"/>
            <path id="Pfad_30" data-name="Pfad 30" d="M594.309,820.325q-3.975.09-7.98.09c-63.43,0-143.21-33.88-215.61-80.78q-8.985-5.82-17.81-11.91l5.16-31.4,156.21-20.74,78.4,59.95Z" transform="translate(-174.731 -79.585)" fill="#2f2e41"/>
            <path id="Pfad_31" data-name="Pfad 31" d="M601.829,259.635h-22.26a2.6,2.6,0,0,0-2.6,2.6V284.5a2.593,2.593,0,0,0,2.6,2.59h22.26a2.6,2.6,0,0,0,2.6-2.59v-22.27a2.608,2.608,0,0,0-2.6-2.6Z" transform="translate(-174.731 -79.585)" fill="#fff"/>
            <path id="Pfad_32" data-name="Pfad 32" d="M601.829,297.585h-22.26a2.6,2.6,0,0,0-2.6,2.6v22.27a2.593,2.593,0,0,0,2.6,2.59h22.26a2.6,2.6,0,0,0,2.6-2.59v-22.27a2.608,2.608,0,0,0-2.6-2.6Z" transform="translate(-174.731 -79.585)" fill="#e6e6e6"/>
            <path id="Pfad_33" data-name="Pfad 33" d="M601.829,335.535h-22.26a2.6,2.6,0,0,0-2.6,2.6v22.26a2.595,2.595,0,0,0,2.6,2.6h22.26a2.6,2.6,0,0,0,2.6-2.6v-22.26A2.608,2.608,0,0,0,601.829,335.535Z" transform="translate(-174.731 -79.585)" fill="#e6e6e6"/>
            <path id="Pfad_34" data-name="Pfad 34" d="M604.389,375.625a2.617,2.617,0,0,0-2.56-2.14h-22.26a2.6,2.6,0,0,0-2.6,2.6v22.26a2.595,2.595,0,0,0,2.6,2.6h22.26a2.6,2.6,0,0,0,2.6-2.6v-22.26a2.311,2.311,0,0,0-.04-.46Z" transform="translate(-174.731 -79.585)" fill="#e6e6e6"/>
            <path id="Pfad_35" data-name="Pfad 35" d="M602.839,411.635a2.634,2.634,0,0,0-1.01-.2h-22.26a2.595,2.595,0,0,0-2.6,2.6v22.26a2.588,2.588,0,0,0,2.6,2.6h22.26a2.6,2.6,0,0,0,2.6-2.6v-22.26a2.616,2.616,0,0,0-1.59-2.4Z" transform="translate(-174.731 -79.585)" fill="#e6e6e6"/>
            <path id="Pfad_36" data-name="Pfad 36" d="M648.589,259.635h-22.26a2.6,2.6,0,0,0-2.6,2.6V284.5a2.593,2.593,0,0,0,2.6,2.59h22.26a2.6,2.6,0,0,0,2.6-2.59v-22.27a2.608,2.608,0,0,0-2.6-2.6Z" transform="translate(-174.731 -79.585)" fill="#e6e6e6"/>
            <path id="Pfad_37" data-name="Pfad 37" d="M648.589,297.585h-22.26a2.6,2.6,0,0,0-2.6,2.6v22.27a2.592,2.592,0,0,0,2.6,2.59h22.26a2.6,2.6,0,0,0,2.6-2.59v-22.27a2.607,2.607,0,0,0-2.6-2.6Z" transform="translate(-174.731 -79.585)" fill="#6c63ff"/>
            <path id="Pfad_38" data-name="Pfad 38" d="M648.589,335.535h-22.26a2.6,2.6,0,0,0-2.6,2.6v22.26a2.595,2.595,0,0,0,2.6,2.6h22.26a2.6,2.6,0,0,0,2.6-2.6v-22.26a2.608,2.608,0,0,0-2.6-2.6Z" transform="translate(-174.731 -79.585)" fill="#6c63ff"/>
            <path id="Pfad_39" data-name="Pfad 39" d="M648.589,373.485h-22.26a2.592,2.592,0,0,0-2.46,1.78,2.477,2.477,0,0,0-.14.82v22.26a2.595,2.595,0,0,0,2.6,2.6h22.26a2.6,2.6,0,0,0,2.6-2.6v-22.26A2.608,2.608,0,0,0,648.589,373.485Z" transform="translate(-174.731 -79.585)" fill="#6c63ff"/>
            <path id="Pfad_40" data-name="Pfad 40" d="M648.589,411.435h-22.26a2.595,2.595,0,0,0-2.6,2.6v22.26a2.595,2.595,0,0,0,2.6,2.6h22.26a2.6,2.6,0,0,0,2.6-2.6v-22.26A2.6,2.6,0,0,0,648.589,411.435Z" transform="translate(-174.731 -79.585)" fill="#e6e6e6"/>
            <path id="Pfad_41" data-name="Pfad 41" d="M695.349,259.635h-22.26a2.6,2.6,0,0,0-2.6,2.6V284.5a2.593,2.593,0,0,0,2.6,2.59h22.26a2.6,2.6,0,0,0,2.6-2.59v-22.27a2.608,2.608,0,0,0-2.6-2.6Z" transform="translate(-174.731 -79.585)" fill="#6c63ff"/>
            <path id="Pfad_42" data-name="Pfad 42" d="M695.349,297.585h-22.26a2.6,2.6,0,0,0-2.6,2.6v22.27a2.592,2.592,0,0,0,2.6,2.59h22.26a2.6,2.6,0,0,0,2.6-2.59v-22.27a2.607,2.607,0,0,0-2.6-2.6Z" transform="translate(-174.731 -79.585)" fill="#6c63ff"/>
            <path id="Pfad_43" data-name="Pfad 43" d="M695.349,335.535h-22.26a2.6,2.6,0,0,0-2.6,2.6v22.26a2.595,2.595,0,0,0,2.6,2.6h22.26a2.6,2.6,0,0,0,2.6-2.6v-22.26A2.608,2.608,0,0,0,695.349,335.535Z" transform="translate(-174.731 -79.585)" fill="#e6e6e6"/>
            <path id="Pfad_44" data-name="Pfad 44" d="M695.349,373.485h-22.26a2.6,2.6,0,0,0-2.6,2.6v22.26a2.595,2.595,0,0,0,2.6,2.6h22.26a2.6,2.6,0,0,0,2.6-2.6v-22.26A2.608,2.608,0,0,0,695.349,373.485Z" transform="translate(-174.731 -79.585)" fill="#6c63ff"/>
            <path id="Pfad_45" data-name="Pfad 45" d="M695.349,411.435h-22.26a2.595,2.595,0,0,0-2.6,2.6v22.26a2.595,2.595,0,0,0,2.6,2.6h22.26a2.6,2.6,0,0,0,2.6-2.6v-22.26a2.6,2.6,0,0,0-2.6-2.6Z" transform="translate(-174.731 -79.585)" fill="#6c63ff"/>
            <path id="Pfad_46" data-name="Pfad 46" d="M742.109,259.635h-22.26a2.6,2.6,0,0,0-2.6,2.6V284.5a2.593,2.593,0,0,0,2.6,2.59h22.26a2.6,2.6,0,0,0,2.6-2.59v-22.27a2.608,2.608,0,0,0-2.6-2.6Z" transform="translate(-174.731 -79.585)" fill="#6c63ff"/>
            <path id="Pfad_47" data-name="Pfad 47" d="M742.109,297.585h-22.26a2.6,2.6,0,0,0-2.6,2.6v22.27a2.592,2.592,0,0,0,2.6,2.59h22.26a2.6,2.6,0,0,0,2.6-2.59v-22.27a2.608,2.608,0,0,0-2.6-2.6Z" transform="translate(-174.731 -79.585)" fill="#fff"/>
            <path id="Pfad_48" data-name="Pfad 48" d="M742.109,335.535h-22.26a2.6,2.6,0,0,0-2.6,2.6v22.26a2.595,2.595,0,0,0,2.6,2.6h22.26a2.6,2.6,0,0,0,2.6-2.6v-22.26A2.608,2.608,0,0,0,742.109,335.535Z" transform="translate(-174.731 -79.585)" fill="#fff"/>
            <path id="Pfad_49" data-name="Pfad 49" d="M742.109,373.485h-22.26a2.6,2.6,0,0,0-2.6,2.6v22.26a2.595,2.595,0,0,0,2.6,2.6h22.26a2.6,2.6,0,0,0,2.6-2.6v-22.26A2.608,2.608,0,0,0,742.109,373.485Z" transform="translate(-174.731 -79.585)" fill="#e6e6e6"/>
            <path id="Pfad_50" data-name="Pfad 50" d="M742.109,411.435h-22.26a2.595,2.595,0,0,0-2.6,2.6v22.26a2.6,2.6,0,0,0,2.6,2.6h22.26a2.6,2.6,0,0,0,2.6-2.6v-22.26A2.6,2.6,0,0,0,742.109,411.435Z" transform="translate(-174.731 -79.585)" fill="#6c63ff"/>
            <path id="Pfad_51" data-name="Pfad 51" d="M788.869,259.635H766.6a2.6,2.6,0,0,0-2.59,2.6V284.5a2.59,2.59,0,0,0,2.59,2.59h22.27a2.6,2.6,0,0,0,2.6-2.59v-22.27a2.608,2.608,0,0,0-2.6-2.6Z" transform="translate(-174.731 -79.585)" fill="#fff"/>
            <path id="Pfad_52" data-name="Pfad 52" d="M788.869,297.585H766.6a2.6,2.6,0,0,0-2.59,2.6v22.27a2.59,2.59,0,0,0,2.59,2.59h22.271a2.6,2.6,0,0,0,2.6-2.59v-22.27a2.608,2.608,0,0,0-2.6-2.6Z" transform="translate(-174.731 -79.585)" fill="#fff"/>
            <path id="Pfad_53" data-name="Pfad 53" d="M788.869,335.535H766.6a2.6,2.6,0,0,0-2.59,2.6v22.26a2.593,2.593,0,0,0,2.59,2.6h22.27a2.6,2.6,0,0,0,2.6-2.6v-22.26A2.608,2.608,0,0,0,788.869,335.535Z" transform="translate(-174.731 -79.585)" fill="#fff"/>
            <path id="Pfad_54" data-name="Pfad 54" d="M788.869,373.485H766.6a2.6,2.6,0,0,0-2.59,2.6v22.26a2.593,2.593,0,0,0,2.59,2.6h22.27a2.6,2.6,0,0,0,2.6-2.6v-22.26a2.608,2.608,0,0,0-2.6-2.6Z" transform="translate(-174.731 -79.585)" fill="#6c63ff"/>
            <path id="Pfad_55" data-name="Pfad 55" d="M788.869,411.435H766.6a2.593,2.593,0,0,0-2.59,2.6v22.26a2.593,2.593,0,0,0,2.59,2.6h22.27a2.6,2.6,0,0,0,2.6-2.6v-22.26A2.6,2.6,0,0,0,788.869,411.435Z" transform="translate(-174.731 -79.585)" fill="#e6e6e6"/>
            <path id="Pfad_56" data-name="Pfad 56" d="M835.629,259.635h-22.27a2.6,2.6,0,0,0-2.59,2.6V284.5a2.59,2.59,0,0,0,2.59,2.59h22.271a2.6,2.6,0,0,0,2.6-2.59v-22.27A2.608,2.608,0,0,0,835.629,259.635Z" transform="translate(-174.731 -79.585)" fill="#fff"/>
            <path id="Pfad_57" data-name="Pfad 57" d="M835.629,297.585h-22.27a2.6,2.6,0,0,0-2.59,2.6v22.27a2.59,2.59,0,0,0,2.589,2.59h22.271a2.6,2.6,0,0,0,2.6-2.59v-22.27A2.608,2.608,0,0,0,835.629,297.585Z" transform="translate(-174.731 -79.585)" fill="#fff"/>
            <path id="Pfad_58" data-name="Pfad 58" d="M835.629,335.535h-22.27a2.6,2.6,0,0,0-2.59,2.6v22.26a2.593,2.593,0,0,0,2.59,2.6h22.27a2.6,2.6,0,0,0,2.6-2.6v-22.26a2.608,2.608,0,0,0-2.6-2.6Z" transform="translate(-174.731 -79.585)" fill="#6c63ff"/>
            <path id="Pfad_59" data-name="Pfad 59" d="M835.629,373.485h-22.27a2.6,2.6,0,0,0-2.59,2.6v22.26a2.593,2.593,0,0,0,2.59,2.6h22.27a2.6,2.6,0,0,0,2.6-2.6v-22.26a2.608,2.608,0,0,0-2.6-2.6Z" transform="translate(-174.731 -79.585)" fill="#e6e6e6"/>
            <path id="Pfad_60" data-name="Pfad 60" d="M835.629,411.435h-22.27a2.593,2.593,0,0,0-2.59,2.6v22.26a2.593,2.593,0,0,0,2.59,2.6h22.27a2.6,2.6,0,0,0,2.6-2.6v-22.26A2.6,2.6,0,0,0,835.629,411.435Z" transform="translate(-174.731 -79.585)" fill="#fff"/>
            <path id="Pfad_61" data-name="Pfad 61" d="M882.389,259.635h-22.27a2.6,2.6,0,0,0-2.59,2.6V284.5a2.59,2.59,0,0,0,2.59,2.59h22.271a2.6,2.6,0,0,0,2.6-2.59v-22.27A2.608,2.608,0,0,0,882.389,259.635Z" transform="translate(-174.731 -79.585)" fill="#6c63ff"/>
            <path id="Pfad_62" data-name="Pfad 62" d="M882.389,297.585h-22.27a2.6,2.6,0,0,0-2.59,2.6v22.27a2.59,2.59,0,0,0,2.589,2.59h22.271a2.6,2.6,0,0,0,2.6-2.59v-22.27A2.608,2.608,0,0,0,882.389,297.585Z" transform="translate(-174.731 -79.585)" fill="#6c63ff"/>
            <path id="Pfad_63" data-name="Pfad 63" d="M882.389,335.535h-22.27a2.6,2.6,0,0,0-2.59,2.6v22.26a2.592,2.592,0,0,0,2.59,2.6h22.27a2.6,2.6,0,0,0,2.6-2.6v-22.26a2.608,2.608,0,0,0-2.6-2.6Z" transform="translate(-174.731 -79.585)" fill="#e6e6e6"/>
            <path id="Pfad_64" data-name="Pfad 64" d="M882.389,373.485h-22.27a2.6,2.6,0,0,0-2.59,2.6v22.26a2.593,2.593,0,0,0,2.59,2.6h22.27a2.6,2.6,0,0,0,2.6-2.6v-22.26a2.608,2.608,0,0,0-2.6-2.6Z" transform="translate(-174.731 -79.585)" fill="#fff"/>
            <path id="Pfad_65" data-name="Pfad 65" d="M882.389,411.435h-22.27a2.593,2.593,0,0,0-2.59,2.6v22.26a2.593,2.593,0,0,0,2.59,2.6h22.27a2.6,2.6,0,0,0,2.6-2.6v-22.26a2.6,2.6,0,0,0-2.6-2.6Z" transform="translate(-174.731 -79.585)" fill="#6c63ff"/>
            <path id="Pfad_66" data-name="Pfad 66" d="M929.149,259.635h-22.27a2.6,2.6,0,0,0-2.59,2.6V284.5a2.591,2.591,0,0,0,2.59,2.59h22.27a2.6,2.6,0,0,0,2.6-2.59v-22.27A2.608,2.608,0,0,0,929.149,259.635Z" transform="translate(-174.731 -79.585)" fill="#6c63ff"/>
            <path id="Pfad_67" data-name="Pfad 67" d="M929.149,297.585h-22.27a2.6,2.6,0,0,0-2.59,2.6v22.27a2.59,2.59,0,0,0,2.589,2.59h22.271a2.6,2.6,0,0,0,2.6-2.59v-22.27A2.608,2.608,0,0,0,929.149,297.585Z" transform="translate(-174.731 -79.585)" fill="#6c63ff"/>
            <path id="Pfad_68" data-name="Pfad 68" d="M929.149,335.535h-22.27a2.6,2.6,0,0,0-2.59,2.6v22.26a2.592,2.592,0,0,0,2.59,2.6h22.27a2.6,2.6,0,0,0,2.6-2.6v-22.26a2.608,2.608,0,0,0-2.6-2.6Z" transform="translate(-174.731 -79.585)" fill="#e6e6e6"/>
            <path id="Pfad_69" data-name="Pfad 69" d="M929.149,373.485h-22.27a2.6,2.6,0,0,0-2.59,2.6v22.26a2.593,2.593,0,0,0,2.59,2.6h22.27a2.6,2.6,0,0,0,2.6-2.6v-22.26a2.608,2.608,0,0,0-2.6-2.6Z" transform="translate(-174.731 -79.585)" fill="#6c63ff"/>
            <path id="Pfad_70" data-name="Pfad 70" d="M929.149,411.435h-22.27a2.593,2.593,0,0,0-2.59,2.6v22.26a2.593,2.593,0,0,0,2.59,2.6h22.27a2.6,2.6,0,0,0,2.6-2.6v-22.26a2.6,2.6,0,0,0-2.6-2.6Z" transform="translate(-174.731 -79.585)" fill="#6c63ff"/>
            <path id="Pfad_71" data-name="Pfad 71" d="M975.909,259.635h-22.27a2.6,2.6,0,0,0-2.59,2.6V284.5a2.59,2.59,0,0,0,2.59,2.59h22.27a2.6,2.6,0,0,0,2.6-2.59v-22.27A2.608,2.608,0,0,0,975.909,259.635Z" transform="translate(-174.731 -79.585)" fill="#e6e6e6"/>
            <path id="Pfad_72" data-name="Pfad 72" d="M975.909,297.585h-22.27a2.6,2.6,0,0,0-2.59,2.6v22.27a2.59,2.59,0,0,0,2.59,2.59h22.271a2.6,2.6,0,0,0,2.6-2.59v-22.27A2.608,2.608,0,0,0,975.909,297.585Z" transform="translate(-174.731 -79.585)" fill="#6c63ff"/>
            <path id="Pfad_73" data-name="Pfad 73" d="M975.909,335.535h-22.27a2.6,2.6,0,0,0-2.59,2.6v22.26a2.592,2.592,0,0,0,2.59,2.6h22.27a2.6,2.6,0,0,0,2.6-2.6v-22.26a2.608,2.608,0,0,0-2.6-2.6Z" transform="translate(-174.731 -79.585)" fill="#6c63ff"/>
            <path id="Pfad_74" data-name="Pfad 74" d="M975.909,373.485h-22.27a2.6,2.6,0,0,0-2.59,2.6v22.26a2.593,2.593,0,0,0,2.59,2.6h22.27a2.6,2.6,0,0,0,2.6-2.6v-22.26a2.608,2.608,0,0,0-2.6-2.6Z" transform="translate(-174.731 -79.585)" fill="#6c63ff"/>
            <path id="Pfad_75" data-name="Pfad 75" d="M975.909,411.435h-22.27a2.593,2.593,0,0,0-2.59,2.6v22.26a2.593,2.593,0,0,0,2.59,2.6h22.27a2.6,2.6,0,0,0,2.6-2.6v-22.26a2.6,2.6,0,0,0-2.6-2.6Z" transform="translate(-174.731 -79.585)" fill="#e6e6e6"/>
            <path id="Pfad_76" data-name="Pfad 76" d="M1022.669,259.635H1000.4a2.6,2.6,0,0,0-2.59,2.6V284.5a2.591,2.591,0,0,0,2.589,2.59h22.271a2.6,2.6,0,0,0,2.6-2.59v-22.27a2.608,2.608,0,0,0-2.6-2.6Z" transform="translate(-174.731 -79.585)" fill="#e6e6e6"/>
            <path id="Pfad_77" data-name="Pfad 77" d="M1022.669,297.585H1000.4a2.6,2.6,0,0,0-2.59,2.6v22.27a2.591,2.591,0,0,0,2.589,2.59h22.271a2.6,2.6,0,0,0,2.6-2.59v-22.27A2.608,2.608,0,0,0,1022.669,297.585Z" transform="translate(-174.731 -79.585)" fill="#e6e6e6"/>
            <path id="Pfad_78" data-name="Pfad 78" d="M1022.669,335.535H1000.4a2.6,2.6,0,0,0-2.59,2.6v22.26a2.593,2.593,0,0,0,2.59,2.6h22.27a2.6,2.6,0,0,0,2.6-2.6v-22.26a2.608,2.608,0,0,0-2.6-2.6Z" transform="translate(-174.731 -79.585)" fill="#6c63ff"/>
            <path id="Pfad_79" data-name="Pfad 79" d="M1022.669,373.485H1000.4a2.6,2.6,0,0,0-2.59,2.6v22.26a2.593,2.593,0,0,0,2.59,2.6h22.27a2.6,2.6,0,0,0,2.6-2.6v-22.26a2.608,2.608,0,0,0-2.6-2.6Z" transform="translate(-174.731 -79.585)" fill="#e6e6e6"/>
            <path id="Pfad_80" data-name="Pfad 80" d="M1022.669,411.435H1000.4a2.593,2.593,0,0,0-2.59,2.6v22.26a2.593,2.593,0,0,0,2.59,2.6h22.27a2.6,2.6,0,0,0,2.6-2.6v-22.26a2.6,2.6,0,0,0-2.6-2.6Z" transform="translate(-174.731 -79.585)" fill="#e6e6e6"/>
            <path id="Pfad_81" data-name="Pfad 81" d="M336.1,660.051h-.62l.53-.07Z" fill="#2f2e41"/>
            <circle id="ab6171fa-7d69-4734-b81c-8dff60f9761b" cx="68.322" cy="68.322" r="68.322" transform="translate(214.493 108.411)" fill="#ffb6b6"/>
            <path id="bf427902-b9bf-4946-b5d7-5c1c7e04535e" d="M517.377,206.226s17.869-34.161-21.444-37.266c0,0-33.515-30.4-67.676-5.558,0,0-18.633,0-28.823,21.086,0,0-14.655-5.558-17.876,9.317,0,0-10.723,31.055,0,59.005s14.285,31.055,14.285,31.055-17.621-58.6,25.27-61.7,90.887-29.909,94.461,4.251,8.953,42.578,8.953,42.578S558.479,220.2,517.377,206.226Z" transform="translate(-174.731 -79.585)" fill="#2f2e41"/>
            <path id="Pfad_82" data-name="Pfad 82" d="M634.279,424.695l-.22,1.16-2.47,13.04-17.07,90.2-1.01,5.31-2.12,11.23-2.9,15.32-6.43,33.95-1.84,9.73-.69,3.65-.24,1.28-1.91,10.07-.58,3.04-4.66,34.53-4.07,30.09,13.62,13.62-8.12,8.12-5.33,30.53.01.07H370.719q-13.2-8.55-26.02-17.63l1.79-13.4s6.16-23.93,3.57-26.79-8.24-7.97-8.24-7.97l7.13-16.64.99-2.31-1.66-34.43-.04-.83-.55-11.35-.07-1.46-.11-2.19-2.11-43.68-.04-.72-.64-13.24-.6-12.59-.26-5.31-2.57-53.13-1.74-36.08-1.52-31.41,6.11-2.07,65.35-22.21L436.559,349l56.34.42,27.58.21,46.02,42.68,10.47,5,.08.04,7.51,3.59,19.04,9.09,20.13,9.62,10.33,4.93Z" transform="translate(-174.731 -79.585)" fill="#cacaca"/>
            <path id="Pfad_83" data-name="Pfad 83" d="M443.84,637.136a30.286,30.286,0,0,1-40.664-22.43L296.617,599.588l37.043-41.9,95.511,20.78a30.45,30.45,0,0,1,14.669,58.67Z" transform="translate(-174.731 -79.585)" fill="#ffb6b6"/>
            <path id="Pfad_84" data-name="Pfad 84" d="M396.329,560.955a13.522,13.522,0,0,0-7.89-4.87l-43.72-9.09-4.49-.93a18.933,18.933,0,0,1-12.53-9.07,19.16,19.16,0,0,1-2.45-7.9,18.9,18.9,0,0,1,.89-7.48l15.15-45.65,9.83-29.62a41.794,41.794,0,0,0,2.09-11.05,43.349,43.349,0,0,0-9.07-28.89,42.693,42.693,0,0,0-49.01-13.64c-.25.09-.51.19-.76.29a42.583,42.583,0,0,0-25.31,28.41l-10.68,38.85-22.03,80.09a57.766,57.766,0,0,0,48.96,72.68l62.97,7.38,29.14,3.42a13.5,13.5,0,0,0,14.84-10.73,4.895,4.895,0,0,0,.11-.55l.7-4.32L399,571.9l.06-.37A13.475,13.475,0,0,0,396.329,560.955Z" transform="translate(-174.731 -79.585)" fill="#cacaca"/>
            <path id="Pfad_85" data-name="Pfad 85" d="M511.714,638.3a30.286,30.286,0,0,0,37.094-27.94L652.17,580.369l-42.582-36.26L517.962,578.15a30.45,30.45,0,0,0-6.249,60.152Z" transform="translate(-174.731 -79.585)" fill="#ffb6b6"/>
            <path id="Pfad_86" data-name="Pfad 86" d="M703.489,513.275l-49.16-113.14a42.819,42.819,0,0,0-20.27-21.35,43.357,43.357,0,0,0-8.78-3.2c-.47-.12-.94-.23-1.41-.32a42.132,42.132,0,0,0-19.48.36,42.814,42.814,0,0,0-27.34,21.73.994.994,0,0,0-.08.14,43.1,43.1,0,0,0-.37,38.85l.61,1.22,34.74,69.77a18.9,18.9,0,0,1-10.5,26.19l-2.44.88-43.88,15.85a13.5,13.5,0,0,0-8.33,16.79l.11.35,12.3,39.36c.06.18.12.36.19.54a7.062,7.062,0,0,0,.4.99,13.482,13.482,0,0,0,15.81,7.53l23.68-6.25,4.83-1.28,61.15-16.15a57.76,57.76,0,0,0,38.22-78.86Z" transform="translate(-174.731 -79.585)" fill="#cacaca"/>
            <path id="Pfad_87" data-name="Pfad 87" d="M219.629,608.285a514.11,514.11,0,0,0,45.75,48.92h451.97a16.446,16.446,0,0,1,16.42,16.43l.65,114.53a355.428,355.428,0,0,0,77.47-48.53q9.84-8.085,19.07-16.87,6.045-5.73,11.8-11.74a355.632,355.632,0,0,0,68.82-102.74Z" transform="translate(-174.731 -79.585)" fill="#3f3d56"/>
            <path id="Pfad_88" data-name="Pfad 88" d="M842.759,711.025q-5.745,6.015-11.8,11.74h-96.21v-11.74Z" transform="translate(-174.731 -79.585)" fill="#fff"/>
            <path id="Pfad_89" data-name="Pfad 89" d="M633.379,335.535a5.538,5.538,0,0,0-4.87-2.9H300.669a5.555,5.555,0,0,0-5.54,5.55V534.4h338.93V338.185a5.385,5.385,0,0,0-.68-2.65Z" transform="translate(-174.731 -79.585)" fill="#3f3d56"/>
            <path id="Pfad_90" data-name="Pfad 90" d="M294.832,529.092v24.214a7.644,7.644,0,0,0,7.644,7.644h125.1v47.787h-1.662a.993.993,0,0,0-.993.993v3.324a.993.993,0,0,0,.993.993h77.658a.993.993,0,0,0,.993-.993V609.73a.993.993,0,0,0-.993-.993h-1.662V560.95H627a7.644,7.644,0,0,0,7.644-7.644V529.092Z" transform="translate(-174.731 -79.585)" fill="#3f3d56"/>
            <circle id="Ellipse_6" data-name="Ellipse 6" cx="31.306" cy="31.306" r="31.306" transform="translate(254.597 317.397)" fill="#6c63ff"/>
            <path id="Pfad_91" data-name="Pfad 91" d="M540.4,252.585h-5c0-37.8-37.41-62.57-75.15-62.59a82.381,82.381,0,0,0-16.35,1.59c-6.06,1.21-12.35,2.66-18.53,4.55-24.37,7.47-46.97,21.91-46.97,56.45a78.59,78.59,0,0,0,2.54,19.88l-4.84,1.26a83.874,83.874,0,0,1-2.7-21.14c0-30.18,12.39-50.91,33.79-63.27,11.25-6.5,24.98-10.69,40.71-12.73a97.13,97.13,0,0,1,19.75-.6C511.049,179.1,540.4,212.385,540.4,252.585Z" transform="translate(-174.731 -79.585)" fill="#6c63ff"/>
            <path id="Pfad_92" data-name="Pfad 92" d="M548.729,255.355l-1.97-14.56a15.914,15.914,0,0,0-18.25-13.56l-3.13.43a6.581,6.581,0,0,0-5.64,7.4l4.55,33.54a6.577,6.577,0,0,0,5.3,5.57,6.157,6.157,0,0,0,1.22.12,6.5,6.5,0,0,0,.879-.06l3.5-.47a15.792,15.792,0,0,0,10.74-6.43c.27-.38.53-.77.77-1.17A16.748,16.748,0,0,0,548.729,255.355Z" transform="translate(-174.731 -79.585)" fill="#6c63ff"/>
            <path id="Pfad_93" data-name="Pfad 93" d="M523.359,228.275a6.432,6.432,0,0,0-2.33,1.92,6.548,6.548,0,0,0-1.29,4.87l4.55,33.54a6.577,6.577,0,0,0,5.3,5.57Z" transform="translate(-174.731 -79.585)" fill="#fff"/>
            <path id="Pfad_94" data-name="Pfad 94" d="M548.729,255.355l-1.97-14.56a15.71,15.71,0,0,0-5-9.56l4.53,33.37a6.175,6.175,0,0,0,.41,1.56,16.748,16.748,0,0,0,2.03-10.81Z" transform="translate(-174.731 -79.585)" fill="#3f3d56"/>
            <ellipse id="Ellipse_7" data-name="Ellipse 7" cx="2.5" cy="7" rx="2.5" ry="7" transform="translate(354.068 172.721) rotate(-7.726)" fill="#3f3d56"/>
            <ellipse id="Ellipse_8" data-name="Ellipse 8" cx="2.5" cy="7" rx="2.5" ry="7" transform="translate(351.068 154.721) rotate(-7.726)" fill="#3f3d56"/>
            <ellipse id="Ellipse_9" data-name="Ellipse 9" cx="2.5" cy="7" rx="2.5" ry="7" transform="translate(370.068 161.721) rotate(-7.726)" fill="#3f3d56"/>
            <path id="Pfad_95" data-name="Pfad 95" d="M460.249,189.995a82.384,82.384,0,0,0-16.35,1.59c-6.06,1.21-12.35,2.66-18.53,4.55-9.65-.47-16.75-2.97-18.18-6.82,11.25-6.5,24.98-10.69,40.71-12.73a97.129,97.129,0,0,1,19.75-.6,5.727,5.727,0,0,1,2.72,3.82C470.839,183.285,466.869,186.985,460.249,189.995Z" transform="translate(-174.731 -79.585)" fill="#3f3d56"/>
            <path id="Pfad_96" data-name="Pfad 96" d="M396.409,287.285l-7.7-32.01a6.024,6.024,0,0,0-4.88-4.55,6.734,6.734,0,0,0-1-.08,6.027,6.027,0,0,0-1.4.17l-2.98.72a14.9,14.9,0,0,0-10.99,17.96l3.45,14.36a15.845,15.845,0,0,0,5.34,8.62,14.045,14.045,0,0,0,1.36.97,14.809,14.809,0,0,0,11.37,1.84l2.97-.72a5.84,5.84,0,0,0,2.17-1.01,5.993,5.993,0,0,0,2.29-6.27Z" transform="translate(-174.731 -79.585)" fill="#6c63ff"/>
            <ellipse id="Ellipse_10" data-name="Ellipse 10" cx="2.5" cy="7" rx="2.5" ry="7" transform="translate(199.83 188.72) rotate(-13.53)" fill="#3f3d56"/>
            <ellipse id="Ellipse_11" data-name="Ellipse 11" cx="2.5" cy="7" rx="2.5" ry="7" transform="translate(189.83 191.72) rotate(-13.53)" fill="#3f3d56"/>
            <path id="Pfad_97" data-name="Pfad 97" d="M396.9,287.165l-7.7-32.01a6.532,6.532,0,0,0-5.29-4.92l10.51,43.72a6.511,6.511,0,0,0,2.48-6.79Z" transform="translate(-174.731 -79.585)" fill="#fff"/>
            <path id="Pfad_98" data-name="Pfad 98" d="M369.2,263.155a6.477,6.477,0,0,0-1.17-2.45,14.726,14.726,0,0,0-.57,8.79l3.45,14.36a15.845,15.845,0,0,0,5.34,8.62Z" transform="translate(-174.731 -79.585)" fill="#3f3d56"/>
          </g>
        </svg>


        <button data-aos="fade-right" id="explorebutton" type="button" name="button"><Link to="/projects">explore all projects</Link></button>
        <Anime
          initial={[
            {
              targets: '#down_arrow_circle',
              translateY: 50,
              direction: 'alternate',
              loop: true,
              easing: 'spring(1, 80, 10, 0)'
            }
          ]}
        >
          <div id="down_arrow_circle" class="down_icon">
            <AnchorLink href='#thirdSite'><img alt="arrowDown" src={arrowdown}></img></AnchorLink>
          </div>
        </Anime>
        <script src="../jumping_arrow.js" charset="utf-8"></script>
      </section>
    );
  }
}

export default FirstSite;
