import React from 'react';
import { Link } from "react-router-dom";
import AOS from "aos";

import './style.css';

class FifthSite extends React.Component {
  componentDidMount() {
    AOS.init({
      duration : 500
    });
  }
  render() {
    return(
      <section class="fifthSite" id="fifthSite">
      <svg xmlns="http://www.w3.org/2000/svg" class="developerSvg" viewBox="0 0 798.627 689.223">
        <g id="Gruppe_5" data-name="Gruppe 5" transform="translate(0 0)">
          <circle id="Ellipse_6" data-name="Ellipse 6" cx="73.536" cy="73.536" r="73.536" transform="translate(362.385 44.869)" fill="#a0616a"/>
          <path id="Pfad_29" data-name="Pfad 29" d="M581.722,260.562S595.577,365,574.262,376.728s126.823,12.789,126.823,12.789-33.038-90.588-18.118-118.3Z" transform="translate(-200.686 -105.389)" fill="#a0616a"/>
          <path id="Pfad_30" data-name="Pfad 30" d="M809.257,390.045l-12.32,56.1-26.05,118.69-.78,6.5-8.99,75.25-5.98,49.99-4.01,33.58c-24.38,12.53-42.05,21.18-42.05,21.18s-1.52-7.62-3.97-17.37c-17.11,5.13-48.77,13.64-75.6,15.34,9.77,10.89,14.26,21.12,8.17,28.67-18.38,22.76-94.26-24.92-120.8-42.7a71.842,71.842,0,0,0-.7,12.86l-20.31-15.62,2.01-35.94,3.39-60.81,4.26-76.26a80.061,80.061,0,0,1-3.37-10.03c-6.22-22.12-13.77-66.29-19.56-103.33-4.66-29.81-8.16-55-8.9-60.36-.1-.7-.15-1.06-.15-1.06l112.43-49.68c7.99,18.53,45.3,24.1,45.3,24.1,30.91-2.13,55.14-18.43,55.14-18.43Z" transform="translate(-200.686 -105.389)" fill="#d0cde1"/>
          <path id="Pfad_31" data-name="Pfad 31" d="M717.607,730.025s-4.76,1.63-12.5,3.94c-17.11,5.13-48.77,13.64-75.6,15.34-23.1,1.48-42.62-2.08-46.19-17.15-1.53-6.48.64-11.81,5.37-16.19,14.12-13.09,51-17.78,80.25-19.39,8.26-.45,15.9-.66,22.26-.74,10.8-.14,17.88.08,17.88.08l.17.66Z" transform="translate(-200.686 -105.389)" fill="#a0616a"/>
          <path id="Pfad_32" data-name="Pfad 32" d="M575.433,154.235l-13.749-5.5s28.749-31.651,68.747-28.9l-11.25-12.385s27.5-11.009,52.5,17.89c13.141,15.191,28.346,33.048,37.825,53.163h14.725l-6.146,13.532,21.509,13.532-22.077-2.431a75.616,75.616,0,0,1,.6,22.445,29.041,29.041,0,0,1-10.634,19h0s-17.052-35.3-17.052-40.8v13.761s-13.749-12.385-13.749-20.642l-7.5,9.633-3.75-15.137-46.248,15.137,7.5-12.385-28.749,4.128,11.25-15.137s-32.5,17.89-33.749,33.027-10.762,29.481-10.762,29.481S539.184,174.877,575.433,154.235Z" transform="translate(-200.686 -105.389)" fill="#2f2e41"/>
          <path id="Pfad_33" data-name="Pfad 33" d="M889.187,647.955c-7.49,8.12-26.05,20.35-48.55,33.59-8.37,4.92-17.28,9.99-26.37,15.03-21.69,12.03-44.4,23.95-63.14,33.58-24.38,12.53-42.05,21.18-42.05,21.18s-1.52-7.62-3.97-17.37c-3.28-13.05-8.22-29.92-13.37-37.39-.18-.26-.36-.5-.54-.74-1.5-1.97-3.01-3.12-4.5-3.12l74.42-46.13,32.16-19.94-23.17-55.31-29.06-69.38,17.54-55.81,17.63-56.1h33.04s10.94,23.88,24.93,57.18c2.09,4.98,4.25,10.17,6.45,15.53C869.417,532.755,905.3,630.5,889.187,647.955Z" transform="translate(-200.686 -105.389)" fill="#d0cde1"/>
          <path id="Pfad_34" data-name="Pfad 34" d="M637.677,777.975c-18.38,22.76-94.26-24.92-120.8-42.7-5.78-3.87-9.22-6.32-9.22-6.32l24.28-32.38,7.69-10.25s6.88,3.65,17.05,9.59c.37.22.74.44,1.12.66,8.77,5.15,19.78,11.88,30.89,19.39,15.33,10.37,30.83,22.2,40.82,33.34C639.277,760.195,643.767,770.425,637.677,777.975Z" transform="translate(-200.686 -105.389)" fill="#a0616a"/>
          <path id="Pfad_35" data-name="Pfad 35" d="M556.677,695.915a47.791,47.791,0,0,0-8.1.66c-22.99,3.95-29.77,24.76-31.7,38.7a71.847,71.847,0,0,0-.7,12.86l-20.31-15.62-7.4-5.69c-17.81-6.21-33.68-17.24-47.44-30.25a238.48,238.48,0,0,1-31.95-37.89,334.145,334.145,0,0,1-24.72-42.4,31.184,31.184,0,0,1-.43-26.45l25.15-56.05,36.76-81.92q.4-2.925.89-5.72c7.29-41.8,26.82-60.36,26.82-60.36h14.92l9.98,60.36,12.4,74.99-8.69,28.34-20.08,65.45,19.19,20.84Z" transform="translate(-200.686 -105.389)" fill="#d0cde1"/>
          <path id="Pfad_36" data-name="Pfad 36" d="M573.563,521.195l12.755,14.881c4.739-.9,9.687-1.965,14.784-3.158l-3.663-11.723,8.958,10.45c54-13.281,120.927-39.1,120.927-39.1s-72.257,5.3-126.208-6.489c-23.871-5.215-48.431,6.618-58.226,29-5.694,13.011-4.8,24.283,15.394,24.283a133.379,133.379,0,0,0,20.353-1.914Z" transform="translate(-200.686 -105.389)" fill="#6c63ff"/>
          <path id="Pfad_37" data-name="Pfad 37" d="M864.337,765.775v6.07a13.338,13.338,0,0,1-.91,4.87,13.693,13.693,0,0,1-.97,2,13.438,13.438,0,0,1-11.55,6.56H404.357a13.437,13.437,0,0,1-11.55-6.56,13.685,13.685,0,0,1-.97-2,13.339,13.339,0,0,1-.91-4.87v-6.07a13.426,13.426,0,0,1,13.43-13.43H430.1v-2.83a.559.559,0,0,1,.56-.56h13.43a.559.559,0,0,1,.56.56v2.83h8.39v-2.83a.559.559,0,0,1,.56-.56h13.43a.559.559,0,0,1,.56.56v2.83h8.4v-2.83a.559.559,0,0,1,.56-.56h13.43a.559.559,0,0,1,.56.56v2.83h8.39v-2.83a.559.559,0,0,1,.56-.56h13.43a.559.559,0,0,1,.56.56v2.83h8.39v-2.83a.559.559,0,0,1,.56-.56h13.43a.559.559,0,0,1,.56.56v2.83h8.4v-2.83a.559.559,0,0,1,.56-.56h13.43a.559.559,0,0,1,.56.56v2.83h8.39v-2.83a.559.559,0,0,1,.56-.56h105.2a.559.559,0,0,1,.56.56v2.83h8.4v-2.83a.559.559,0,0,1,.56-.56h13.43a.566.566,0,0,1,.56.56v2.83h8.39v-2.83a.559.559,0,0,1,.56-.56h13.43a.559.559,0,0,1,.56.56v2.83h8.39v-2.83a.559.559,0,0,1,.56-.56h13.43a.559.559,0,0,1,.56.56v2.83h8.4v-2.83a.559.559,0,0,1,.56-.56H765.3a.557.557,0,0,1,.55.56v2.83h8.4v-2.83a.559.559,0,0,1,.56-.56h13.43a.559.559,0,0,1,.56.56v2.83h8.39v-2.83a.559.559,0,0,1,.56-.56h13.43a.559.559,0,0,1,.56.56v2.83h39.17a13.426,13.426,0,0,1,13.43,13.43Z" transform="translate(-200.686 -105.389)" fill="#3f3d56"/>
          <rect id="Rechteck_12" data-name="Rechteck 12" width="733" height="2" transform="translate(60.5 671.326)" fill="#3f3d56"/>
          <path id="Pfad_38" data-name="Pfad 38" d="M828.381,510.145H661.938v-3.431H586.466v3.431H419.337A11.259,11.259,0,0,0,408.079,521.4V749.315a11.259,11.259,0,0,0,11.259,11.259H828.381a11.259,11.259,0,0,0,11.259-11.259V521.4a11.259,11.259,0,0,0-11.259-11.259Z" transform="translate(-200.686 -105.389)" fill="#3f3d56"/>
          <circle id="Ellipse_7" data-name="Ellipse 7" cx="25" cy="25" r="25" transform="translate(398.5 459.326)" fill="none" stroke="#d0cde1" stroke-miterlimit="10" stroke-width="2"/>
          <circle id="Ellipse_8" data-name="Ellipse 8" cx="25" cy="25" r="25" transform="translate(390.5 468.326)" fill="#d0cde1"/>
          <rect id="Rechteck_13" data-name="Rechteck 13" width="28" height="28" transform="translate(80.446 661.223)" fill="#d0cde1"/>
          <path id="Pfad_39" data-name="Pfad 39" d="M292.136,744.612v34h34v-34Zm32.52,32.52h-31.05v-31.04h31.05Z" transform="translate(-200.686 -105.389)" fill="#3f3d56"/>
          <rect id="Rechteck_14" data-name="Rechteck 14" width="28" height="28" transform="translate(728.446 661.223)" fill="#d0cde1"/>
          <path id="Pfad_40" data-name="Pfad 40" d="M940.136,744.612v34h34v-34Zm32.52,32.52h-31.05v-31.04h31.05Z" transform="translate(-200.686 -105.389)" fill="#3f3d56"/>
          <circle id="Ellipse_9" data-name="Ellipse 9" cx="79.34" cy="79.34" r="79.34" transform="translate(15.24 71.432)" fill="#6c63ff"/>
          <path id="Pfad_41" data-name="Pfad 41" d="M322.915,161.879a85.756,85.756,0,0,0-21.638,0,84.368,84.368,0,0,0-69.615,58.712,83.222,83.222,0,0,0-3.655,21.662c-.036,1.022-.06,2.056-.06,3.089A84.233,84.233,0,0,0,312.1,329.491c1.791,0,3.582-.06,5.349-.168a83.232,83.232,0,0,0,12.334-1.707,84.159,84.159,0,0,0-6.864-165.737Zm15.519,160.857h-.012a80.906,80.906,0,0,1-20.977,4.171c-1.767.12-3.558.18-5.35.18a81.845,81.845,0,0,1-81.745-81.745c0-1.034.024-2.068.06-3.089a81.744,81.744,0,1,1,108.023,80.482Z" transform="translate(-200.686 -105.389)" fill="#3f3d56"/>
          <rect id="Rechteck_15" data-name="Rechteck 15" width="2.404" height="16.83" transform="translate(110.208 63.018)" fill="#3f3d56"/>
          <rect id="Rechteck_16" data-name="Rechteck 16" width="2.404" height="16.83" transform="translate(56.159 86.401) rotate(-45)" fill="#3f3d56"/>
          <rect id="Rechteck_17" data-name="Rechteck 17" width="2.404" height="16.83" transform="translate(34.474 141.156) rotate(-90)" fill="#3f3d56"/>
          <rect id="Rechteck_18" data-name="Rechteck 18" width="2.404" height="16.83" transform="translate(57.858 195.204) rotate(-135)" fill="#3f3d56"/>
          <rect id="Rechteck_19" data-name="Rechteck 19" width="2.404" height="16.83" transform="translate(154.761 98.3) rotate(-135)" fill="#3f3d56"/>
          <rect id="Rechteck_20" data-name="Rechteck 20" width="2.404" height="16.83" transform="translate(171.516 141.156) rotate(-90)" fill="#3f3d56"/>
          <rect id="Rechteck_21" data-name="Rechteck 21" width="2.404" height="16.83" transform="translate(153.064 183.305) rotate(-45)" fill="#3f3d56"/>
          <rect id="Rechteck_22" data-name="Rechteck 22" width="2.404" height="16.83" transform="translate(110.208 200.06)" fill="#3f3d56"/>
          <path id="Pfad_42" data-name="Pfad 42" d="M315.7,240.534a6.011,6.011,0,0,0-7.213,0,6.187,6.187,0,0,0-1.719,2.02,6.011,6.011,0,1,0,8.932-2.02Zm-3.606,8.415a3.617,3.617,0,0,1-3.606-3.606,3.671,3.671,0,0,1,.637-2.044,3.6,3.6,0,1,1,2.969,5.65Z" transform="translate(-200.686 -105.389)" fill="#3f3d56"/>
          <rect id="Rechteck_23" data-name="Rechteck 23" width="21.638" height="21.638" transform="translate(100.591 35.369)" fill="#3f3d56"/>
          <ellipse id="Ellipse_10" data-name="Ellipse 10" cx="28.851" cy="10.218" rx="28.851" ry="10.218" transform="translate(82.559 18.539)" fill="#3f3d56"/>
          <rect id="Rechteck_24" data-name="Rechteck 24" width="7.213" height="40.872" transform="translate(107.803 94.273)" fill="#3f3d56"/>
          <rect id="Rechteck_25" data-name="Rechteck 25" width="226" height="2.404" transform="translate(0 220.44)" fill="#3f3d56"/>
          <path id="Pfad_43" data-name="Pfad 43" d="M351.28,705.143c.933,31.95-17.735,43.659-41.163,44.343q-.816.024-1.625.029-1.63.012-3.228-.054c-21.188-.88-37.96-12.127-38.828-41.84-.9-30.75,37.269-70.7,40.133-73.658l.005,0,.164-.169S350.347,673.2,351.28,705.143Z" transform="translate(-200.686 -105.389)" fill="#d0cde1"/>
          <path id="Pfad_44" data-name="Pfad 44" d="M308.43,744.644l14.882-22.131-14.852,24.512.031,2.49q-1.63.012-3.228-.054L306,717.449l-.02-.247.027-.048.071-3.025-16.3-23.663,16.279,21.4.058.639.558-24.186L292.6,663.786l14.117,20.284-.147-50.108V633.8l0,.164.934,39.491,12.829-16.041L307.55,676.853l.28,21.634,11.806-21.114-11.762,24.294.156,12.03L325.2,684.286l-17.117,33.608Z" transform="translate(-200.686 -105.389)" fill="#3f3d56"/>
          <path id="Pfad_45" data-name="Pfad 45" d="M999.28,705.143c.933,31.95-17.735,43.659-41.163,44.343q-.816.024-1.625.029-1.63.012-3.228-.054c-21.188-.88-37.96-12.127-38.828-41.84-.9-30.75,37.269-70.7,40.133-73.658l.005,0,.164-.169S998.347,673.2,999.28,705.143Z" transform="translate(-200.686 -105.389)" fill="#d0cde1"/>
          <path id="Pfad_46" data-name="Pfad 46" d="M956.43,744.644l14.882-22.131-14.852,24.512.031,2.49q-1.63.012-3.228-.054L954,717.449l-.02-.247.027-.048.071-3.025-16.3-23.663,16.279,21.4.058.639.557-24.186L940.6,663.786l14.117,20.284-.147-50.108V633.8l0,.164.934,39.491,12.83-16.041L955.55,676.853l.28,21.634,11.806-21.114-11.762,24.294.156,12.03L973.2,684.286l-17.117,33.608Z" transform="translate(-200.686 -105.389)" fill="#3f3d56"/>
        </g>
      </svg>

        <div class="explore">
          <h2 data-aos="fade-up">Explore my projects</h2>
          <p data-aos="fade-up">Start now and take a look at my projects or take part in the "Coding Challenges" part. There are no limits here. I am always open to ideas for new projects or for already completed projects.</p>
          <Link to="/projects"><button type="button" id="start_now"name="button">START NOW</button></Link>
        </div>
      </section>
    );
  };
}

export default FifthSite;
