import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import {Link } from "react-router-dom";
import $ from 'jquery';

import './style.css';

import logo from './logo.png';


var projectNames = [];
var projectLinks = [];
var generalPoints = [];
var generalPointLinks = [];
var searchResults;

var generalPoint = {
  home: {
    url: "/home",
    searchTerms: ["home", "mainpage", "startpage"]
  },
  projects: {
    url: "/projects",
    searchTerms: ["code", "projects", "degreeofdif"],
  },
  contact: {
    url: "/contact",
    searchTerms: ["social media", "contact", "email", "message", "instagram", "github"]
  }
}

generalPoints.push(generalPoint.home.searchTerms, generalPoint.projects.searchTerms, generalPoint.contact.searchTerms)
generalPointLinks.push(generalPoint.home.url, generalPoint.projects.url, generalPoint.contact.url);

const element = <FontAwesomeIcon icon={faCog} class="icon"/>

class SearchInput extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(e) {
    this.props.onCurrencyChange(e.target.value);
  }
  render() {
    return(
      <input placeholder="Search..." id="search" onChange={this.handleChange} />
    );
  }
}

class Navigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {value: '', isLoaded: false, projectsResults: false, generalPointResults: false};
    this.handleValueChange = this.handleValueChange.bind(this);
  }
  handleValueChange(value) {
    this.setState({
      value: value.toLowerCase()
    });
  }
  componentDidMount() {
    var path = 'https://javascriptcoding.herokuapp.com/data';
    fetch(path, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json'}
    }).then(
        res => res.json())
      .then(
        (result) => {
            this.setState({
              isLoaded: true,
              projectsResults: result
            });
        },
        (error) => {
            this.setState({
              isLoaded: false,
              error
            });
          }
      )
  }
  showSearchResults(value) {
    searchResults = [];
    if (this.state.value.length !== 0) {
      outerloop:
        for (let i = 0; i < this.state.projectsResults.data.length; i++) {
          if (!projectNames.includes(this.state.projectsResults.data[i].p_name)) {
            projectNames.push(this.state.projectsResults.data[i].p_name);
            projectLinks.push(this.state.projectsResults.data[i].p_link);
          }
        }
        outerloop:
        for (let i = 0; i < projectNames.length; i++) {
          for (let j = 0; j < projectNames[i].length; j++) {
            if (this.state.value === projectNames[i].slice(j, j+this.state.value.length)) {
              if (!searchResults.includes(projectNames[i])) {
                searchResults.push(<li><a href={projectLinks[i]} target="_blank">{projectNames[i]}</a></li>);
                continue outerloop;
              }
            }
          }
        }
        for (let i = 0; i < generalPoints.length; i++) {
          outerloop:
          for (let j = 0; j < generalPoints[i].length; j++) {
            for (let k = 0; k < generalPoints[i][j].length; k++) {
              if (this.state.value === generalPoints[i][j].slice(k, k+this.state.value.length)) {
                if (!searchResults.includes(generalPoints[i][j])) {
                  searchResults.push(<li><a href={generalPointLinks[i]}>{generalPoints[i][j]}</a></li>);
                  console.log(searchResults);
                  continue outerloop;
                }
              }
            }
          }
        }
    }
    return searchResults;
  }
  handleSettingsClick() {
    $(".dropdown").toggleClass("active");
    $(".blackBackground").toggleClass("active");
    $(".endMobileView").toggleClass("active");
  }
  handleMenuClick() {
    $(".button-toggle").toggleClass("toggled-on");
		$(".option-btn").removeClass("active");
		$(".optionbutton").removeClass("active");
    $(".mobile-navigation ul").toggleClass("active");
    $(".endMobileView").toggleClass("active");
  }
  handleBlackClick() {
    $(".endMobileView").removeClass("active");
    $(".button-toggle").removeClass("toggled-on");
		$(".option-btn").removeClass("active");
		$(".optionbutton").removeClass("active");
    $(".mobile-navigation ul").removeClass("active");
  }
  handleLinkClick() {
	   $(".mobile-navigation ul").removeClass("active");
     $(".button-toggle").removeClass("toggled-on");
     $(".endMobileView").toggleClass("active");
  }
  render() {
    return(
      <nav>
        <div class="logo"><img alt="logo" id="logo" src={logo}/></div>
        <div class="mobile-navigation">
          <a id="menu-toggle" class="menu-toggle" onClick={this.handleMenuClick}><span class="button-toggle"></span></a>
          <ul>
            <li onClick={this.handleLinkClick}><Link class="navLinks" to="/home">Home</Link></li>
            <li onClick={this.handleLinkClick}><Link class="navLinks" to="/projects">Projects</Link></li>
            <li onClick={this.handleLinkClick}><a href="https://blog.javascriptcoding.org" class="navLinks" target="_blank">Blog</a></li>
            <li onClick={this.handleLinkClick}><Link class="navLinks" to="/contact">Contact</Link></li>
          </ul>
        </div>
        <div onClick={this.handleBlackClick} class="endMobileView"></div>
        <div class="desktop-navigation">
          <ul>
            <li onClick={this.handleLinkClick}><Link class="navLinks" to="/home">Home</Link></li>
            <li onClick={this.handleLinkClick}><Link class="navLinks" to="/projects">Projects</Link></li>
            <li onClick={this.handleLinkClick}><a href="https://blog.javascriptcoding.org" class="navLinks" target="_blank">Blog</a></li>
            <li onClick={this.handleLinkClick}><Link class="navLinks" to="/contact">Contact</Link></li>
          </ul>
          <div id="settings" class="settings" onClick={this.handleSettingsClick}>{element}</div>
          <div class="blackBackground"></div>
          <div class="dropdown">
            {<SearchInput value="" onCurrencyChange={this.handleValueChange} />}
            <div>
              <ul class="searchResults">
              {this.showSearchResults()}
              </ul>
            </div>
          </div>
          </div>
      </nav>
    );
  }
}

export default Navigation;
