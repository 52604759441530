import React from 'react';
import { Link } from "react-router-dom";
import AOS from "aos";

import './style.css';
import './typewriter.js';
import './hover-animation.js';
import './hover-animation.css';

class ThirdSite extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reload: false
    };
  }
  componentDidMount() {
    AOS.init({
      duration : 500
    });
  }
  render() {
    return(
      <section class="thirdSite" id="thirdSite">
        <div class="bewhatyouwant">
					<p id="wrap">Be what you want:<br /><a href="#" class="typewrite" dataPeriod="2000" dataType='[ "Programmer", "Designer", "Explorer" ]'>Programmer</a></p>
					<span class="wrap"></span>
				</div>
        <svg xmlns="http://www.w3.org/2000/svg" class="visualizationSvg" viewBox="0 0 693.973 712.573">
          <g id="Gruppe_5" data-name="Gruppe 5" transform="translate(0 0)">
            <path id="Path_968" data-name="Path 968" d="M752.758,262.605h-3.9V155.628a61.915,61.915,0,0,0-61.915-61.915H460.293a61.915,61.915,0,0,0-61.916,61.915V742.511a61.915,61.915,0,0,0,61.915,61.915H686.94a61.915,61.915,0,0,0,61.915-61.915V338.753h3.9Z" transform="translate(-253.014 -93.713)" fill="#3f3d56"/>
            <path id="Path_969" data-name="Path 969" d="M736.158,151.481V746.656a46.958,46.958,0,0,1-46.935,46.952H457.916a46.967,46.967,0,0,1-46.973-46.959V151.482a46.965,46.965,0,0,1,46.971-46.951h28.058a22.329,22.329,0,0,0,20.656,30.74H638.5a22.329,22.329,0,0,0,20.656-30.74h30.055a46.957,46.957,0,0,1,46.951,46.934Z" transform="translate(-253.014 -93.713)" fill="#fff"/>
            <path id="Path_39" data-name="Path 39" d="M678.824,494.309H473.287a3.81,3.81,0,0,1-3.806-3.806V439.52a3.811,3.811,0,0,1,3.806-3.806H678.824a3.811,3.811,0,0,1,3.806,3.806V490.5a3.811,3.811,0,0,1-3.806,3.806ZM473.287,437.235A2.286,2.286,0,0,0,471,439.519V490.5a2.286,2.286,0,0,0,2.284,2.284H678.824a2.286,2.286,0,0,0,2.284-2.284V439.519a2.286,2.286,0,0,0-2.284-2.284Z" transform="translate(-253.014 -93.713)" fill="#e6e6e6"/>
            <path id="Path_40" data-name="Path 40" d="M536.852,454.074a2.665,2.665,0,0,0,0,5.329h125.6a2.665,2.665,0,0,0,.2-5.326l-.117,0H536.851Z" transform="translate(-253.014 -93.713)" fill="#e6e6e6"/>
            <path id="Path_41" data-name="Path 41" d="M536.852,470.058a2.664,2.664,0,1,0,0,5.329h125.6a2.665,2.665,0,0,0,.2-5.326l-.117,0H536.851Z" transform="translate(-253.014 -93.713)" fill="#e6e6e6"/>
            <path id="Path_42" data-name="Path 42" d="M678.824,579.289H473.287a3.81,3.81,0,0,1-3.806-3.806V524.5a3.811,3.811,0,0,1,3.806-3.806H678.824a3.811,3.811,0,0,1,3.806,3.806v50.985A3.811,3.811,0,0,1,678.824,579.289ZM473.287,522.216A2.286,2.286,0,0,0,471,524.5v50.985a2.286,2.286,0,0,0,2.284,2.284H678.824a2.286,2.286,0,0,0,2.284-2.284V524.5a2.286,2.286,0,0,0-2.284-2.284Z" transform="translate(-253.014 -93.713)" fill="#e6e6e6"/>
            <path id="Path_43" data-name="Path 43" d="M536.852,539.33a2.664,2.664,0,0,0,0,5.329h125.6a2.665,2.665,0,1,0,.087-5.329H536.851Z" transform="translate(-253.014 -93.713)" fill="#e6e6e6"/>
            <path id="Path_44" data-name="Path 44" d="M536.852,555.318a2.664,2.664,0,0,0,0,5.329h125.6a2.665,2.665,0,1,0,.087-5.329H536.851Z" transform="translate(-253.014 -93.713)" fill="#e6e6e6"/>
            <path id="Path_39-2" data-name="Path 39-2" d="M678.824,664.547H473.287a3.81,3.81,0,0,1-3.806-3.806V609.758a3.811,3.811,0,0,1,3.806-3.806H678.824a3.811,3.811,0,0,1,3.806,3.806v50.985a3.811,3.811,0,0,1-3.806,3.806ZM473.287,607.474A2.286,2.286,0,0,0,471,609.758v50.985a2.286,2.286,0,0,0,2.284,2.284H678.824a2.286,2.286,0,0,0,2.284-2.284V609.758a2.286,2.286,0,0,0-2.284-2.284Z" transform="translate(-253.014 -93.713)" fill="#e6e6e6"/>
            <path id="Path_40-2" data-name="Path 40-2" d="M536.852,624.591a2.664,2.664,0,0,0,0,5.329h125.6a2.665,2.665,0,0,0,.2-5.326l-.117,0H536.851Z" transform="translate(-253.014 -93.713)" fill="#e6e6e6"/>
            <path id="Path_41-2" data-name="Path 41-2" d="M536.852,640.578a2.664,2.664,0,0,0,0,5.329h125.6a2.665,2.665,0,0,0,.2-5.326l-.117,0H536.851Z" transform="translate(-253.014 -93.713)" fill="#e6e6e6"/>
            <path id="Path_970" data-name="Path 970" d="M945.173,806.286H254.826c-1,0-1.812-.468-1.812-1.045s.812-1.045,1.812-1.045H945.174c1,0,1.812.468,1.812,1.045S946.173,806.286,945.173,806.286Z" transform="translate(-253.014 -93.713)" fill="#e6e6e6"/>
            <g id="Group_58" data-name="Group 58">
              <path id="Path_438" data-name="Path 438" d="M282.086,765.522a19.474,19.474,0,0,0,18.806-3.313c6.587-5.528,8.652-14.637,10.332-23.07l4.97-24.945-10.4,7.165c-7.483,5.152-15.134,10.47-20.316,17.933s-7.443,17.651-3.28,25.727" transform="translate(-253.014 -93.713)" fill="#e6e6e6"/>
              <path id="Path_439" data-name="Path 439" d="M283.693,797.458c-1.31-9.542-2.657-19.206-1.738-28.85.816-8.565,3.429-16.93,8.749-23.789a39.575,39.575,0,0,1,10.153-9.2c1.015-.641,1.95.968.939,1.606a37.622,37.622,0,0,0-14.885,17.955c-3.24,8.241-3.76,17.224-3.2,25.978.338,5.294,1.053,10.553,1.774,15.806a.964.964,0,0,1-.65,1.144.936.936,0,0,1-1.144-.65Z" transform="translate(-253.014 -93.713)" fill="#f2f2f2"/>
              <path id="Path_442" data-name="Path 442" d="M293.12,782.149a14.336,14.336,0,0,0,12.491,6.447c6.323-.3,11.6-4.713,16.34-8.9L335.987,767.3l-9.289-.444c-6.68-.32-13.533-.618-19.9,1.442s-12.231,7.018-13.394,13.6" transform="translate(-253.014 -93.713)" fill="#e6e6e6"/>
              <path id="Path_443" data-name="Path 443" d="M279.991,802.944c6.3-11.156,13.618-23.555,26.685-27.518a29.782,29.782,0,0,1,11.224-1.159c1.192.1.894,1.94-.3,1.837a27.665,27.665,0,0,0-17.912,4.739c-5.051,3.438-8.983,8.217-12.311,13.286-2.039,3.1-3.865,6.341-5.691,9.573C281.1,804.734,279.4,803.989,279.991,802.944Z" transform="translate(-253.014 -93.713)" fill="#f2f2f2"/>
            </g>
            <g id="Group_59" data-name="Group 59">
              <circle id="Ellipse_5" data-name="Ellipse 5" cx="15.986" cy="15.986" r="15.986" transform="translate(229.929 354.999)" fill="#6c63ff"/>
              <path id="Path_40-3" data-name="Path 40-3" d="M491.276,461.712c-.184,0-.333,1.193-.333,2.664s.149,2.665.333,2.665h15.719c.184.024.336-1.149.339-2.62a5.943,5.943,0,0,0-.328-2.708h-15.73Z" transform="translate(-253.014 -93.713)" fill="#e6e6e6"/>
            </g>
            <g id="Group_60" data-name="Group 60">
              <circle id="Ellipse_5-2" data-name="Ellipse 5-2" cx="15.986" cy="15.986" r="15.986" transform="translate(229.929 440.292)" fill="#6c63ff"/>
              <path id="Path_40-4" data-name="Path 40-4" d="M491.276,547.005c-.184,0-.333,1.193-.333,2.664s.149,2.665.333,2.665h15.719c.184.024.336-1.149.339-2.62a5.943,5.943,0,0,0-.328-2.708h-15.73Z" transform="translate(-253.014 -93.713)" fill="#e6e6e6"/>
            </g>
            <g id="Group_61" data-name="Group 61">
              <circle id="Ellipse_5-3" data-name="Ellipse 5-3" cx="15.986" cy="15.986" r="15.986" transform="translate(229.929 525.55)" fill="#6c63ff"/>
              <path id="Path_40-5" data-name="Path 40-5" d="M491.276,632.263c-.184,0-.333,1.193-.333,2.664s.149,2.665.333,2.665h15.719c.184.024.336-1.149.339-2.62a5.943,5.943,0,0,0-.328-2.708h-15.73Z" transform="translate(-253.014 -93.713)" fill="#e6e6e6"/>
            </g>
            <path id="Pfad_29" data-name="Pfad 29" d="M625.2,350.382a4.487,4.487,0,0,1-4.012-2.45,52.875,52.875,0,0,0-31.372-26.411,60,60,0,0,0-8.408-1.9l-.58-.088,29.92-93.546.506.018c58.128,15.795,91.385,62.381,100.193,76.283a4.494,4.494,0,0,1-1.8,6.423l-82.431,41.2a4.507,4.507,0,0,1-2.019.478Z" transform="translate(-253.014 -93.713)" fill="#e6e6e6"/>
            <path id="Path_2881" data-name="Path 2881" d="M804.637,549.706a9.276,9.276,0,0,1,12.711-6.383l22.283-24.293,4.164,16.616-21.8,20.521a9.326,9.326,0,0,1-17.359-6.462Z" transform="translate(-253.014 -93.713)" fill="#feb8b8"/>
            <path id="Path_2882" data-name="Path 2882" d="M852.495,792.044H838.882L832.4,739.527h20.1Z" transform="translate(-253.014 -93.713)" fill="#feb8b8"/>
            <path id="Path_2883" data-name="Path 2883" d="M855.968,787.6H829.153a17.089,17.089,0,0,0-17.088,17.086v.557h43.9Z" transform="translate(-253.014 -93.713)" fill="#2f2e41"/>
            <path id="Path_2884" data-name="Path 2884" d="M930.684,778.556l-12.729,4.832-24.693-46.8,18.787-7.13Z" transform="translate(-253.014 -93.713)" fill="#feb8b8"/>
            <path id="Path_2885" data-name="Path 2885" d="M932.355,773.167l-25.069,9.515h0a17.089,17.089,0,0,0-9.911,22.039l.2.519,41.045-15.578Z" transform="translate(-253.014 -93.713)" fill="#2f2e41"/>
            <path id="Path_2886" data-name="Path 2886" d="M885.865,617.36l11.283,60.714s30.625,85.428,28.476,87.577-31.7,9.671-30.088,4.836-35.461-77.369-35.461-77.369Z" transform="translate(-253.014 -93.713)" fill="#2f2e41"/>
            <path id="Path_2887" data-name="Path 2887" d="M840.109,507.03s-22.777,29.774-19.792,31.4,14.547,10.236,14.547,10.236l11.764-32.284Z" transform="translate(-253.014 -93.713)" fill="#e4e4e4"/>
            <path id="Path_2888" data-name="Path 2888" d="M833.748,537.083s-3.761,27.939-2.149,33.849.537,173.543-.537,176.229-4.3,11.82-2.686,16.118,23.64,15.044,26.864,11.283,26.866-140.768,26.866-140.768,33.312-82.742,25.79-90.8S833.748,537.083,833.748,537.083Z" transform="translate(-253.014 -93.713)" fill="#2f2e41"/>
            <circle id="Ellipse_542" data-name="Ellipse 542" cx="26.327" cy="26.327" r="26.327" transform="translate(602.745 211.155)" fill="#feb8b8"/>
            <path id="Path_2890" data-name="Path 2890" d="M854.576,390.7a18.665,18.665,0,0,1,6-11.04l4.8-14.675h28.209l7.861,12.335c8.5,3.661,14.8,9.467,15.039,16.311,1.3,4.545-4.3,148.828-5.91,154.2-.473,1.564-6.9,2.4-15.936,2.772-8.285.338-18.756.29-28.809.059-16.645-.387-32.162-1.279-34.773-1.757C825.152,547.828,853.991,394.086,854.576,390.7Z" transform="translate(-253.014 -93.713)" fill="#e4e4e4"/>
            <path id="Path_2891" data-name="Path 2891" d="M879.516,582.026a9.276,9.276,0,0,0,1.826-14.106l15.569-29.056-17.059,1.558-12.168,27.352a9.326,9.326,0,0,0,11.833,14.251Z" transform="translate(-253.014 -93.713)" fill="#feb8b8"/>
            <path id="Path_2893" data-name="Path 2893" d="M882.1,374.823s-24.715,10.208-15.581,36,19.342,56.415,19.342,56.415-12.9,69.31-12.9,72-8.059,17.193-4.836,19.88,24.715,8.059,25.252,5.373a181.714,181.714,0,0,1,5.91-18.268c1.075-2.149,26.327-69.31,23.1-75.22-2.372-4.349-4.454-44.2-5.388-64.939a34.107,34.107,0,0,0-17.781-28.715C894.223,374.688,888.391,373.251,882.1,374.823Z" transform="translate(-253.014 -93.713)" fill="#e4e4e4"/>
            <path id="Path_2800" data-name="Path 2800" d="M892.219,342.962c-2-.922-4.317-1.113-6.479-1.686-7.734-2.052-12.916-9.689-11.261-16.6a7.479,7.479,0,0,0,.406-2.736c-.289-2.047-2.687-3.368-4.986-3.837s-4.746-.4-6.943-1.155a9.39,9.39,0,0,1-6.136-7.366,13.674,13.674,0,0,1,2.327-9.171l.831,2.088a7.771,7.771,0,0,0,2.714-3.545,5.5,5.5,0,0,1,4.26,2.992c1.333.687,1.525-2.133,3-2.549a2.945,2.945,0,0,1,1.838.4c2.967,1.209,6.414.175,9.6-.495a41.222,41.222,0,0,1,16.771-.017c3.663.763,7.29,2.093,9.912,4.455a20.351,20.351,0,0,1,4.636,6.96c2.812,6.182,4.669,12.871,3.741,19.473a26.05,26.05,0,0,1-7.436,14.6c-2.123,2.137-9.05,9.591-12.848,8.321C891.388,351.494,897.842,345.56,892.219,342.962Z" transform="translate(-253.014 -93.713)" fill="#2f2e41"/>
            <circle id="Ellipse_6" data-name="Ellipse 6" cx="8" cy="8" r="8" transform="translate(313.979 258.459)" fill="#3f3d56"/>
            <path id="Pfad_30" data-name="Pfad 30" d="M521.3,350.265a4.492,4.492,0,0,1-2.1-.524l-81.953-43.234a4.5,4.5,0,0,1-1.741-6.325A181.666,181.666,0,0,1,476.5,254.207c28.3-22.431,60.494-33.684,95.678-33.5A151.884,151.884,0,0,1,611.244,226l.5.137-29.927,93.541-.421-.063a66.681,66.681,0,0,0-8.139-.722c-30.451-.859-43.646,19.876-47.908,28.83a4.414,4.414,0,0,1-2.62,2.31,4.528,4.528,0,0,1-1.429.233Z" transform="translate(-253.014 -93.713)" fill="#6c63ff"/>
            <path id="Pfad_31" data-name="Pfad 31" d="M611.122,226.483l-21.16,94.56a60.388,60.388,0,0,0-8.48-1.92l29.63-92.64Z" transform="translate(-253.014 -93.713)" fill="#3f3d56"/>
          </g>
        </svg>

        <div id="containeroption" class="containeroption">
			  	<div class="split left">
			       <h1 data-aos="fade-up" class="pictureheadlines headlineleft">Design</h1>
			    	<Link data-aos="fade-up" to="/projects/css" class="button">Explore</Link>
			  	</div>
			  	<div class="split right">
			    	<h1 data-aos="fade-up" class="pictureheadlines">Develop</h1>
			    	<Link data-aos="fade-up" to="/projects/javascript" class="button">Explore</Link>
			  	</div>
				</div>
      </section>
    );
  }
}

export default ThirdSite;
