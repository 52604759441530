import './App.css';

import {BrowserRouter as Router, Route} from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faEnvelope, faUser } from '@fortawesome/free-solid-svg-icons';
import { Redirect } from 'react-router';
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

import ScrollToTop from './pages/scrollTop.js';

import Navigation from './pages/navigation/navigation.js';
import FirstSite from './pages/home/firstSite/firstSite.js';
import SecondSite from './pages/home/secondSite/secondSite.js';
import ThirdSite from './pages/home/thirdSite/thirdSite.js';
import FourthSite from './pages/home/fourthSite/fourthSite.js';
import FifthSite from './pages/home/fifthSite/fifthSite.js';
import Footer from './pages/footer/footer.js'

import ProjectSite from './pages/projects/projects.js';

import Contact from './pages/contact/contact.js';


library.add(faEnvelope, faUser);



function App() {
  return (
    <Router>
      <ScrollToTop />
      <Route path="/" component={Navigation} />
      <Route path="/home" component={FirstSite}/>
      <Route path="/home" component={SecondSite}/>
      <Route path="/home" component={ThirdSite}/>
      <Route path="/home" component={FourthSite}/>
      <Route path="/home" component={FifthSite}/>
      <Route path="/projects" component={ProjectSite}/>
      <Route path="/contact" component={Contact}/>
      <Route path="/" component={Footer} />
      <Route exact path="" render={() => (
        <Redirect to="/home"/>
      )}/>
    </Router>
  );
}

export default App;
