import React from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import AOS from "aos";

import FilterButtonDegreeOfDif from './filterButtonDegreeOfDif.js';
import FilterButtonCategory from './filterButtonCategory.js';

import './style.css';
import './footerPosition.js';

var projects = [];
var names = [];
var degreeofdifNum = undefined;

if (window.location.pathname !== "/projects" && !window.location.pathname.includes("home") && !window.location.pathname.includes("contact")) {
  window.location.href = "/projects";
}


class ProjectSite extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      projects: null,
      starone: false,
      startwo: false,
      starthree: false,
      starfour: false,
      starfive: false,
    };
    this.handleClickOnestar = this.handleClickOnestar.bind(this);
    this.handleClickTwostar = this.handleClickTwostar.bind(this);
    this.handleClickThreestar = this.handleClickThreestar.bind(this);
    this.handleClickFourstar = this.handleClickFourstar.bind(this);
    this.handleClickFivestar = this.handleClickFivestar.bind(this);
  }
  handleFilterClick() {
    $(".filterField").toggleClass("active");
    $(".allProjects").toggleClass("active");
    $("#filter").toggleClass("active");
    $(".filterMobileSlide").toggleClass("active");
    if (document.getElementById("filterTitle").innerText == "Filter...") {
      document.getElementById("filterTitle").innerText = "Close...";
    } else {
      document.getElementById("filterTitle").innerText = "Filter...";
    }
  }
  componentDidMount() {
    document.getElementById("loader").classList.add("active");
    var path = 'https://javascriptcoding.herokuapp.com/data';
    fetch(path, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json'}
    }).then(
        res => res.json())
      .then(
        (result) => {
          document.getElementById("loader").classList.remove("active");
            this.setState({
              isLoaded: true,
              projects: result
            });
        },
        (error) => {
            this.setState({
              isLoaded: false,
              error
            });
          }
      )
      setInterval(() => {
        if (window.location.pathname.includes("onestar")) {
          this.setState({
            starone: true, startwo: false, starthree: false, starfour: false, starfive: false
          });
        } else if (window.location.pathname.includes("twostar")) {
          this.setState({
            starone: true, startwo: true, starthree: false, starfour: false, starfive: false
          });
        } else if (window.location.pathname.includes("threestar")) {
          this.setState({
            starone: true, startwo: true, starthree: true, starfour: false, starfive: false
          });
        } else if (window.location.pathname.includes("fourstar")) {
          this.setState({
            starone: true, startwo: true, starthree: true, starfour: true, starfive: false
          });
        } else if (window.location.pathname.includes("fivestar")) {
          this.setState({
            starone: true, startwo: true, starthree: true, starfour: true, starfive: true
          });
        } else {
          this.setState({
            starone: false, startwo: false, starthree: false, starfour: false, starfive: false
          });
        }
      }, 1000);

      AOS.init({
        duration : 700,
        delay: 500
      });
  }
  pushProjects(i) {
    if (this.state.projects !== null && projects.length < this.state.projects.data.length) {
      projects.push(<a href={this.state.projects.data[i].p_link} target="_blank">
                      <ul>
                        <div class="projectHead">
                          <li>{this.state.projects.data[i].p_name[0].toUpperCase()+this.state.projects.data[i].p_name.slice(1, this.state.projects.data[i].p_name.length)}</li><br />
                        </div>
                        <div class="openCloseInformation"></div>
                        <div class="projectInformation">
                          <li>Language: {this.state.projects.data[i].p_language}</li><br />
                          <li>Degree of difficulty: {this.state.projects.data[i].p_degreeofdif}</li>
                        </div>
                      </ul>
                    </a>);
    }
  }
  allProjects() {
    projects = [];
    if (this.state.isLoaded && this.state.projects != null) {
        for (var i = 0; i < this.state.projects.data.length; i++) {
          names.push(this.state.projects.data[i].p_name)
          if (window.location.pathname === "/projects") {
            this.pushProjects(i);
          } else if (window.location.pathname === "/projects/javascript" && this.state.projects.data[i].p_language === "Javascript") {
            this.pushProjects(i);
          } else if (window.location.pathname === "/projects/javascript/onestar" && this.state.projects.data[i].p_language === "Javascript" && this.state.projects.data[i].p_degreeofdif === 1) {
            this.pushProjects(i);
          } else if (window.location.pathname === "/projects/javascript/twostar" && this.state.projects.data[i].p_language === "Javascript" && this.state.projects.data[i].p_degreeofdif === 2) {
            this.pushProjects(i);
          } else if (window.location.pathname === "/projects/javascript/threestar" && this.state.projects.data[i].p_language === "Javascript" && this.state.projects.data[i].p_degreeofdif === 3) {
            this.pushProjects(i);
          } else if (window.location.pathname === "/projects/javascript/fourstar" && this.state.projects.data[i].p_language === "Javascript" && this.state.projects.data[i].p_degreeofdif === 4) {
            this.pushProjects(i);
          } else if (window.location.pathname === "/projects/javascript/fivestar" && this.state.projects.data[i].p_language === "Javascript" && this.state.projects.data[i].p_degreeofdif === 5) {
            this.pushProjects(i);
          } else if (window.location.pathname === "/projects/css" && this.state.projects.data[i].p_language === "CSS") {
            this.pushProjects(i);
          } else if (window.location.pathname === "/projects/css/onestar" && this.state.projects.data[i].p_language === "CSS" && this.state.projects.data[i].p_degreeofdif === 1) {
            this.pushProjects(i);
          } else if (window.location.pathname === "/projects/css/twostar" && this.state.projects.data[i].p_language === "CSS" && this.state.projects.data[i].p_degreeofdif === 2) {
            this.pushProjects(i);
          } else if (window.location.pathname === "/projects/css/threestar" && this.state.projects.data[i].p_language === "CSS" && this.state.projects.data[i].p_degreeofdif === 3) {
            this.pushProjects(i);
          } else if (window.location.pathname === "/projects/css/fourstar" && this.state.projects.data[i].p_language === "CSS" && this.state.projects.data[i].p_degreeofdif === 4) {
            this.pushProjects(i);
          } else if (window.location.pathname === "/projects/css/fivestar" && this.state.projects.data[i].p_language === "CSS" && this.state.projects.data[i].p_degreeofdif === 5) {
            this.pushProjects(i);
          } else if (window.location.pathname === "/projects/onestar" && this.state.projects.data[i].p_degreeofdif === 1) {
            this.pushProjects(i);
          } else if (window.location.pathname === "/projects/twostar" && this.state.projects.data[i].p_degreeofdif === 2) {
            this.pushProjects(i);
          } else if (window.location.pathname === "/projects/threestar" && this.state.projects.data[i].p_degreeofdif === 3) {
            this.pushProjects(i);
          } else if (window.location.pathname === "/projects/fourstar" && this.state.projects.data[i].p_degreeofdif === 4) {
            this.pushProjects(i);
          } else if (window.location.pathname === "/projects/fivestar" && this.state.projects.data[i].p_degreeofdif === 5) {
            this.pushProjects(i);
          }
        }
        return projects;
    } else {
      return null;
    }
  }

  handleClickOnestar() {
    this.setState({
      starone: true,
      startwo: false,
      starthree: false,
      starfour: false,
      starfive: false
    });
  }
  handleClickTwostar() {
    this.setState({
      starone: true,
      startwo: true,
      starthree: false,
      starfour: false,
      starfive: false
    });
  }
  handleClickThreestar() {
    this.setState({
      starone: true,
      startwo: true,
      starthree: true,
      starfour: false,
      starfive: false
    });
  }
  handleClickFourstar() {
    this.setState({
      starone: true,
      startwo: true,
      starthree: true,
      starfour: true,
      starfive: false
    });
  }
  handleClickFivestar() {
    this.setState({
      starone: true,
      startwo: true,
      starthree: true,
      starfour: true,
      starfive: true
    });
  }

  render() {

    const ConditionalLinkJS = ({ firstIf, secondIf, thirdIf, fourthIf, fifthIf, sixthIf }) => (window.location.pathname.includes("onestar")) ? <Link class="javascriptFilterLink" to={firstIf}>Javascript</Link> : (window.location.pathname.includes("twostar")) ? <Link class="javascriptFilterLink" to={secondIf}>Javascript</Link> : (window.location.pathname.includes("threestar")) ? <Link class="javascriptFilterLink" to={thirdIf}>Javascript</Link> : (window.location.pathname.includes("fourstar")) ? <Link class="javascriptFilterLink" to={fourthIf}>Javascript</Link> : (window.location.pathname.includes("fivestar")) ? <Link class="javascriptFilterLink" to={fifthIf}>Javascript</Link> : (window.location.pathname === "/projects" || window.location.pathname === "/projects/css") ? <Link class="javascriptFilterLink" to={sixthIf}>Javascript</Link> : <Link class="javascriptFilterLink" to="/projects">Javascript</Link>;
    const ConditionalLinkCSS = ({ firstIf, secondIf, thirdIf, fourthIf, fifthIf, sixthIf }) => (window.location.pathname.includes("onestar")) ? <Link class="cssFilterLink" to={firstIf}>CSS</Link> : (window.location.pathname.includes("twostar")) ? <Link class="cssFilterLink" to={secondIf}>CSS</Link> : (window.location.pathname.includes("threestar")) ? <Link class="cssFilterLink" to={thirdIf}>CSS</Link> : (window.location.pathname.includes("fourstar")) ? <Link class="cssFilterLink" to={fourthIf}>CSS</Link> : (window.location.pathname.includes("fivestar")) ? <Link class="cssFilterLink" to={fifthIf}>CSS</Link> : (window.location.pathname === "/projects" || window.location.pathname === "/projects/javascript") ? <Link class="cssFilterLink" to={sixthIf}>CSS</Link> : <Link class="cssFilterLink" to="/projects">CSS</Link>;
    const ConditionalLinkStar = ({ children, firstIf, secondIf, thirdIf }) => (window.location.pathname.includes("javascript")) ? <Link to={firstIf}>{children}</Link> : (window.location.pathname.includes("css")) ? <Link to={secondIf}>{children}</Link> : (window.location.pathname === "/projects" || window.location.pathname === "/projects/onestar" || window.location.pathname === "/projects/twostar" || window.location.pathname === "/projects/threestar" || window.location.pathname === "/projects/fourstar" || window.location.pathname === "/projects/fivestar") ? <Link to={thirdIf}>{children}</Link> : <Link to="/projects">{children}</Link>;

    return(
      <section class="projectSite">
        <div id="filter" class="filter" onClick={this.handleFilterClick}><p><a id="filterTitle">Filter...</a></p></div>
        <div class="filterField">
          <h1>Filter menu:</h1>
          <div class="degreeofdifFilter">
            <h2>Degree of difficulty:</h2>
            <div class="inputs">
              <ConditionalLinkStar firstIf="/projects/javascript/onestar" secondIf="/projects/css/onestar" thirdIf="/projects/onestar"><input type="checkbox" id="oneStar" name="firstStar" value="firstStar" checked={this.state.starone} onChange={this.handleClickOnestar} /></ConditionalLinkStar>
              <ConditionalLinkStar firstIf="/projects/javascript/twostar" secondIf="/projects/css/twostar" thirdIf="/projects/twostar"><input type="checkbox" id="twoStar" name="secondStar" value="secondStar" checked={this.state.startwo} onChange={this.handleClickTwostar} /></ConditionalLinkStar>
              <ConditionalLinkStar firstIf="/projects/javascript/threestar" secondIf="/projects/css/threestar" thirdIf="/projects/threestar"><input type="checkbox" id="threeStar" name="thirdStar" value="thirdStar" checked={this.state.starthree} onChange={this.handleClickThreestar} /></ConditionalLinkStar>
              <ConditionalLinkStar firstIf="/projects/javascript/fourstar" secondIf="/projects/css/fourstar" thirdIf="/projects/fourstar"><input type="checkbox" id="fourStar" name="fourthStar" value="fourthStar" checked={this.state.starfour} onChange={this.handleClickFourstar} /></ConditionalLinkStar>
              <ConditionalLinkStar firstIf="/projects/javascript/fivestar" secondIf="/projects/css/fivestar" thirdIf="/projects/fivestar"><input type="checkbox" id="fiveStar" name="fifthStar" value="fifthStar" checked={this.state.starfive} onChange={this.handleClickFivestar} /></ConditionalLinkStar>
            </div>
          </div>
          <div class="categoryFilter">
            <h2>Languages:</h2>
            <button id="javascriptFilter"><ConditionalLinkJS firstIf="/projects/javascript/onestar" secondIf="/projects/javascript/twostar" thirdIf="/projects/javascript/threestar" fourthIf="/projects/javascript/fourstar" fifthIf="/projects/javascript/fivestar" sixthIf="/projects/javascript"></ConditionalLinkJS></button>
            <button id="cssFilter"><ConditionalLinkCSS firstIf="/projects/css/onestar" secondIf="/projects/css/twostar" thirdIf="/projects/css/threestar" fourthIf="/projects/css/fourstar" fifthIf="/projects/css/fivestar" sixthIf="/projects/css"></ConditionalLinkCSS></button>
          </div>
          <div class="filterMobileSlide"></div>
        </div>
        <div class="currentFilter">
          <FilterButtonDegreeOfDif />
          <FilterButtonCategory />
        </div><br />
        <div id="loader">Loading...</div>
        <div id="allProjects" class="allProjects">
          {this.allProjects()}
        </div>
      </section>
    );
  }
}

export default ProjectSite;
