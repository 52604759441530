import React from 'react';
import $ from 'jquery';

import './style.css';

class FourthSite extends React.Component {
  openReadMore() {
    $(".moreAboutMe").addClass("active");
    document.getElementById("moreAboutMeContent").innerHTML = "<h1 id='moreAboutTitle'>Paul Dunz</h1><br><p id='moreAboutMeText'>I've been programming for about 4 years now and have built this website over the years. The programming languages I've learned over time are Javascript and Python. I learned several libraries, too, like \"React.js\". I coded many algorithms, like the \"A* Pathfinding\" or the \"Selectionsort\" and started learning AI and ML in python.</p>";
  }
  closeReadMore() {
    $(".moreAboutMe").removeClass("active");
    document.getElementById("moreAboutMeContent").innerHTML = "";
  }
  render() {
    return(
      <section class="fourthSite" id="fourthSite">
        <div class="splittedParts">
          <div class="workpictures">
            <img alt="codeImg" data-aos="fade-up" src="https://photos.programs-with-code.com/Programm1.jpg" class="workimgone" />
            <img alt="codeImg" data-aos="fade-up" src="https://photos.programs-with-code.com/Programm2.jpg" class="workimgtwo" />
            <img alt="codeImg" data-aos="fade-up" src="https://photos.programs-with-code.com/Programm3.jpg" class="workimgthree" />
          </div>
          <div class="textPart">
            <h1>What am i doing?</h1>
            <p>I've been programming for about 4 years now and have built this website over the years. The programming languages I've learned over time are Javascript and Python...</p>
            <button type="button" id="readMore" name="button" onClick={this.openReadMore}>READ MORE</button>
            <div class="moreAboutMe">
              <div class="closeMoreAboutMe" onClick={this.closeReadMore}>&#10006;</div>
              <div id="moreAboutMeContent" class="content">
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  };
}

export default FourthSite;
