import React from 'react';

class LatestProjects extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      projects: null
    };
  }
  componentDidMount() {
      document.getElementById("loader").classList.add("active");
      document.getElementById("divAllProjects").classList.add("active");
      var path = 'https://javascriptcoding.herokuapp.com/data';
      fetch(path, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json'}
      }).then(
          res => res.json())
        .then(
          (result) => {
            document.getElementById("loader").classList.remove("active");
            document.getElementById("divAllProjects").classList.remove("active");
              this.setState({
                isLoaded: true,
                projects: result
              });
          },
          (error) => {
              this.setState({
                isLoaded: false,
                error
              });
            }
        )
  }
  createTable() {
    var table = [];
    if (this.state.isLoaded && this.state.projects != null) {
      for (var i = 0; i < this.state.projects.data.length; i++) {
        if (i < 3) {
          table.push(<a href={this.state.projects.data[i].p_link} target="_blank">
                          <ul>
                            <div class="projectHead">
                              <li>{this.state.projects.data[i].p_name[0].toUpperCase()+this.state.projects.data[i].p_name.slice(1, this.state.projects.data[i].p_name.length)}</li><br />
                            </div>
                            <div class="openCloseInformation"></div>
                            <div class="projectInformation">
                              <li>Language: {this.state.projects.data[i].p_language}</li><br />
                              <li>Degree of difficulty: {this.state.projects.data[i].p_degreeofdif}</li>
                            </div>
                          </ul>
                        </a>);
            }
        }
        return table;
    } else {
      table.push(<p>Something went wrong!</p>)
    }
  }
  render() {
    return(
      <div class="latestProjects">{this.createTable()}</div>
    );
  }
}

export default LatestProjects;
