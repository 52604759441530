import React from 'react';
import { Link } from "react-router-dom";
import logo from './logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faPinterest } from "@fortawesome/free-brands-svg-icons";



import './style.css';

class Footer extends React.Component {
  render() {
    return(
      <div class="footer">
        <div class="topPartFooter">
          <div class="footerLogo">
            <img alt="logo" id="footerLogoIcon" src={logo}/>
          </div>
          <div class="siteLinks">
            <div class="LinkPart">
              <h2>Get started</h2>
              <ul>
                <li><Link to="/home">Home</Link></li>
                <li><Link to="/projects">Projects</Link></li>
                <li><Link to="/contact">Contact</Link></li>
              </ul>
            </div>
            <div class="LegacyPart">
              <h2>Legacy</h2>
              <ul>
                <li>Privacy</li>
                <li>Imprint</li>
              </ul>
            </div>
            <div class="socialMediaPart">
              <h2>Social media</h2>
              <ul>
                <li><a href="https://www.instagram.com/javascript_coding/">Instagram</a></li>
                <li><a href="https://www.pinterest.de/306658dd9e653fadf0e142828ae844/">Pinterest</a></li>
              </ul>
            </div>
          </div>
          <div class="footerSocialMedia">
            <a href="https://www.instagram.com/javascript_coding/">
              <FontAwesomeIcon icon={faInstagram} size="2x" />
            </a>
            <a href="https://www.pinterest.de/306658dd9e653fadf0e142828ae844/">
              <FontAwesomeIcon icon={faPinterest} size="2x" />
            </a><br />
            <button id="footerContactButton"><Link to="/contact">Contact us</Link></button>
          </div>
        </div>
        <div id="bottomPartFooter" class="bottomPartFooter">
          <p>&copy; 2021 javascriptcoding.org</p>
          <p>Paul Dunz All Rights Reserved</p>
        </div>
      </div>
    );
  }
}

export default Footer;
