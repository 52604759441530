import React, { useState } from "react";
import swal from 'sweetalert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faPinterest } from "@fortawesome/free-brands-svg-icons";

import './style.css';

if (window.location.pathname === "/contact/mailsend") {
    swal({
      icon: 'success',
      title: 'Your email was sent!',
      text: 'You will get an answer as soon as possible.',
    }).then(function() {
        window.location = "/contact";
    });
}

const Contact = () => {
  const [status, setStatus] = useState("Send");
  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus("Sending...");
    const { name, email, message } = e.target.elements;
    let details = {
      name: name.value,
      email: email.value,
      message: message.value,
    };
    let response = await fetch("https://javascriptcoding.org/contact", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(details),
    });
    setStatus("Sent!");
    window.location.pathname = "/contact/mailsend";
  };
  return (
    <div class="background">
      <div class="email">
        <form class="emailform" onSubmit={handleSubmit}>
          <span class="emailformTitle">Send a message</span>
          <div class="inputDiv">
    				<input class="input" id="name_area" type="text" name="name" placeholder="Name" required />
    				<span class="focus_input"></span>
    				<span class="symbol_input">
    					<FontAwesomeIcon icon="user"/>
    				</span>
    			</div>
          <div class="inputDiv">
    				<input class="input" id="email_area" type="text" name="email" placeholder="Email" required />
    				<span class="focus_input"></span>
    				<span class="symbol_input">
              <FontAwesomeIcon icon="envelope"/>
    				</span>
    			</div>
    			<div class="inputDiv">
    				<textarea id="text_area" class="input" name="message" placeholder="Message" rows="10" required></textarea>
    			</div>
          <div class="sendMail">
    				<button class="emailBtn" id="send" type="submit" name="submit">{status}</button>
    			</div>
        </form>
        <div class="socialMedia">
          <span class="socialMediaTitle">Social Media:</span>
          <div class="socialMediaOptions">
          <a href="https://www.instagram.com/javascript_coding">
            <div class="instagramField">
              <label>Instagram</label>
              <FontAwesomeIcon icon={faInstagram} size="2x" />
            </div>
          </a>
          <a href="https://www.instagram.com/javascript_coding">
            <div class="pinterestField">
              <label>Pinterest</label>
              <FontAwesomeIcon icon={faPinterest} size="2x" />
            </div>
          </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
