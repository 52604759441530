import React from 'react';
import {Link } from "react-router-dom";



class FilterButtonDegreeOfDif extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      render: true,
      degreeofdif: null
    }
  }
  delete() {
    this.setState({
      render: false
    })
  }
  componentDidMount() {
    setInterval(() => {
      this.setState({
        render: (window.location.pathname.includes("onestar") || window.location.pathname.includes("twostar") || window.location.pathname.includes("threestar") || window.location.pathname.includes("fourstar") || window.location.pathname.includes("fivestar")) ? true : false,
        degreeofdif: (window.location.pathname.includes("onestar")) ? "1" : (window.location.pathname.includes("twostar")) ? "2" : (window.location.pathname.includes("threestar")) ? "3" : (window.location.pathname.includes("fourstar")) ? "4" : (window.location.pathname.includes("fivestar")) ? "5" : "error"
      })
    }, 200)
  }
  render() {
    const ConditionalLink = ({ firstIf, secondIf, thirdIf }) => (window.location.pathname.includes("javascript/")) ? <Link class="removeDegreeOfDifLink" to={firstIf}><button id="removeDegreeOfDif" onClick={(e) => this.delete(e)}></button><br /></Link> : (window.location.pathname.includes("css/")) ? <Link class="removeDegreeOfDifLink" to={secondIf}><button id="removeDegreeOfDif" onClick={(e) => this.delete(e)}></button><br /></Link> : (window.location.pathname === "/projects/onestar" || window.location.pathname === "/projects/twostar" || window.location.pathname === "/projects/threestar" || window.location.pathname === "/projects/fourstar" || window.location.pathname === "/projects/fivestar") ? <Link class="removeDegreeOfDifLink" to={thirdIf}><button id="removeDegreeOfDif" onClick={(e) => this.delete(e)}></button><br /></Link> : <Link class="removeDegreeOfDifLink" to="/projects"><button id="removeDegreeOfDif" onClick={(e) => this.delete(e)}></button><br /></Link>;
    if (this.state.render) {
      return(
        <div class="degreeofdifButton">
          <p>Degree of difficulty:</p>
          <p id="degreeOfDifState">{this.state.degreeofdif}</p>
          <ConditionalLink class="removeDegreeOfDifLink" firstIf="/projects/javascript" secondIf="/projects/css" thirdIf="/projects"></ConditionalLink>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default FilterButtonDegreeOfDif;
