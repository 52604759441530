import React from 'react';
import { Link } from "react-router-dom";

import LatestProjects from './latestProjects.js'

import './style.css';

class SecondSite extends React.Component {
  render() {
    return(
      <section class="secondSite" id="secondSite">
        <h1>Latest projects</h1>
        <hr id="latestprojectshr" />
        <svg xmlns="http://www.w3.org/2000/svg" class="thinkingPersonSvg" viewBox="0 0 650.502 698.685">
          <g id="Gruppe_5" data-name="Gruppe 5" transform="translate(0 0)">
            <path id="Pfad_29" data-name="Pfad 29" d="M415.557,383.62c-30.666-30.188-43.617-61.682-36.47-88.679,9.172-34.645,49.918-58.6,111.791-65.72l.229,1.987c-61.011,7.021-101.136,30.438-110.085,64.245-6.956,26.273,5.808,57.079,35.94,86.742Z" transform="translate(-274.749 -100.658)" fill="#f0f0f0"/>
            <path id="Pfad_30" data-name="Pfad 30" d="M711.427,490.778c-38.272,0-79.862-5.687-121.173-16.624C539.168,460.63,492.328,440.216,454.8,415.12l1.111-1.662c37.35,24.975,83.983,45.3,134.857,58.763,45.975,12.172,92.294,17.811,133.934,16.323l.072,2Q718.176,490.779,711.427,490.778Z" transform="translate(-274.749 -100.658)" fill="#f0f0f0"/>
            <path id="Pfad_31" data-name="Pfad 31" d="M799.229,478.2l-.641-1.895c34.229-11.573,55.639-30.216,61.913-53.916,7.976-30.127-9.352-65.088-48.79-98.443-39.64-33.524-96.8-61.34-160.956-78.324l.512-1.934C715.7,260.749,773.142,288.709,813,322.421,853.064,356.3,870.62,391.987,862.435,422.9,855.981,447.282,834.124,466.4,799.229,478.2Z" transform="translate(-274.749 -100.658)" fill="#f0f0f0"/>
            <path id="Pfad_32" data-name="Pfad 32" d="M484.922,491.614c-75.2-33.354-133.153-76.625-163.173-121.846l1.666-1.106c29.813,44.907,87.458,87.923,162.318,121.124Z" transform="translate(-274.749 -100.658)" fill="#f0f0f0"/>
            <path id="Pfad_33" data-name="Pfad 33" d="M749.331,549.4q-11.635,0-23.668-.52l.086-2c59.864,2.592,112.684-4.572,152.758-20.722l.748,1.855C844.3,542.1,799.74,549.4,749.331,549.4Z" transform="translate(-274.749 -100.658)" fill="#f0f0f0"/>
            <path id="Pfad_34" data-name="Pfad 34" d="M705.7,231.015c-12.43-4.047-25.215-7.815-38-11.2-73.574-19.478-145.9-26.393-209.155-20l-.2-1.99c63.494-6.419,136.065.513,209.868,20.052,12.821,3.395,25.643,7.173,38.107,11.232Z" transform="translate(-274.749 -100.658)" fill="#f0f0f0"/>
            <path id="Pfad_35" data-name="Pfad 35" d="M368.557,386.62c-30.666-30.188-43.617-61.682-36.47-88.679,9.172-34.645,49.918-58.6,111.791-65.72l.229,1.987c-61.011,7.021-101.136,30.438-110.085,64.245-6.956,26.273,5.808,57.079,35.94,86.742Z" transform="translate(-274.749 -100.658)" fill="#f0f0f0"/>
            <path id="Pfad_36" data-name="Pfad 36" d="M664.427,493.778c-38.272,0-79.862-5.687-121.173-16.624C492.168,463.63,445.328,443.216,407.8,418.12l1.111-1.662c37.35,24.975,83.983,45.3,134.857,58.763,45.975,12.172,92.294,17.811,133.934,16.323l.072,2Q671.176,493.779,664.427,493.778Z" transform="translate(-274.749 -100.658)" fill="#f0f0f0"/>
            <path id="Pfad_37" data-name="Pfad 37" d="M752.229,481.2l-.641-1.895c34.229-11.573,55.639-30.216,61.913-53.916,7.976-30.127-9.352-65.088-48.79-98.443-39.64-33.524-96.8-61.34-160.956-78.324l.512-1.934C668.7,263.749,726.142,291.709,766,325.421,806.064,359.3,823.62,394.987,815.435,425.9,808.981,450.282,787.124,469.4,752.229,481.2Z" transform="translate(-274.749 -100.658)" fill="#f0f0f0"/>
            <path id="Pfad_38" data-name="Pfad 38" d="M437.922,494.614c-75.2-33.354-133.153-76.625-163.173-121.846l1.666-1.106c29.813,44.907,87.458,87.923,162.318,121.124Z" transform="translate(-274.749 -100.658)" fill="#f0f0f0"/>
            <path id="Pfad_39" data-name="Pfad 39" d="M702.331,552.4q-11.635,0-23.668-.52l.086-2c59.864,2.592,112.684-4.572,152.758-20.722l.748,1.855C797.3,545.1,752.74,552.4,702.331,552.4Z" transform="translate(-274.749 -100.658)" fill="#f0f0f0"/>
            <path id="Pfad_40" data-name="Pfad 40" d="M658.7,234.015c-12.43-4.047-25.215-7.815-38-11.2-73.574-19.478-145.9-26.393-209.155-20l-.2-1.99c63.494-6.419,136.065.513,209.868,20.052,12.821,3.395,25.643,7.173,38.107,11.232Z" transform="translate(-274.749 -100.658)" fill="#f0f0f0"/>
            <g id="Group_221" data-name="Group 221">
              <path id="Path_2938" data-name="Path 2938" d="M671.134,414.039l17-2,6,52,15.319,71.808a11,11,0,1,1-11.933,1.5l-15.386-42.308Z" transform="translate(-274.749 -100.658)" fill="#ffb6b6"/>
              <path id="Path_2939" data-name="Path 2939" d="M646.438,784.753h14.006l6.664-54.025H646.436Z" transform="translate(-274.749 -100.658)" fill="#ffb6b6"/>
              <path id="Path_2940" data-name="Path 2940" d="M642.866,798.327h45.162v-.571a17.579,17.579,0,0,0-17.576-17.578H642.866Z" transform="translate(-274.749 -100.658)" fill="#2f2e41"/>
              <path id="Path_2941" data-name="Path 2941" d="M538.948,777.114l13.752,2.655,16.788-51.78-20.3-3.919Z" transform="translate(-274.749 -100.658)" fill="#ffb6b6"/>
              <path id="Path_2942" data-name="Path 2942" d="M532.87,789.767l44.343,8.563.108-.561a17.58,17.58,0,0,0-13.926-20.593h0l-27.083-5.23Z" transform="translate(-274.749 -100.658)" fill="#2f2e41"/>
              <path id="Path_2943" data-name="Path 2943" d="M668.4,328.826l-8.138,15.614L599.311,331l6.677-30.273c5.285-23.962,26.472-41.267,50.434-35.982s35.142,28.7,29.857,52.667l-9.822,30.6-9.938-2.192Z" transform="translate(-274.749 -100.658)" fill="#2f2e41"/>
              <path id="Path_2944" data-name="Path 2944" d="M690.134,501.04s6,30-2,74-10,63-10,63,6,7,2,11,0,12,0,12l-9,97-31-5,4-91s-4-8-2-10-2-14-2-14l-5-95-31,104s3,4,0,7-9,18-9,18l-20,70-32-6,18-80s-2-5,1-8a7.834,7.834,0,0,0,2-7s-3-3,1-7,6-20,6-20,3-107,13-113S690.134,501.04,690.134,501.04Z" transform="translate(-274.749 -100.658)" fill="#2f2e41"/>
              <circle id="Ellipse_569" data-name="Ellipse 569" cx="28" cy="28" r="28" transform="translate(342.168 178.921)" fill="#ffb6b6"/>
              <path id="Path_2945" data-name="Path 2945" d="M605.457,343.42s-20.324-2.381-26.324,3.619l6,121s-4,3-2,10a28.568,28.568,0,0,1,0,14s-6,7-3,10,111,1,111,1,4-7-2-11-3-15-3-15,2-3-3-8-9-46-9-46l13-50s-13.086-14.771-28.043-15.885Z" transform="translate(-274.749 -100.658)" fill="#e6e6e6"/>
              <path id="Path_2946" data-name="Path 2946" d="M671.639,373.726l15.493-.688s9,18,10,34l5.507,59.688-35.507,5.312Z" transform="translate(-274.749 -100.658)" fill="#e6e6e6"/>
              <path id="Path_2947" data-name="Path 2947" d="M668.906,308.967l10.4-13.382a10.911,10.911,0,0,0-8.282-12.969l-39.292-8.667a10.912,10.912,0,0,0-12.97,8.282l-.022.1,2.814,16.159.494-.088c24.385-4.348,31.331-2.818,46.459,10.226Z" transform="translate(-274.749 -100.658)" fill="#2f2e41"/>
              <path id="Path_2948" data-name="Path 2948" d="M564.258,522.78l1.875-78.74,2-56,24,3L579.244,524.885a11,11,0,1,1-15.458-1.751q.231-.184.471-.355Z" transform="translate(-274.749 -100.658)" fill="#ffb6b6"/>
              <path id="Path_2949" data-name="Path 2949" d="M596.64,348.726l-17.507-1.688s-3,0-6,10-19.492,115.688-19.492,115.688l36.493,3.312Z" transform="translate(-274.749 -100.658)" fill="#e6e6e6"/>
            </g>
            <circle id="Ellipse_563" data-name="Ellipse 563" cx="45.498" cy="45.498" r="45.498" transform="translate(110.668 19.537)" fill="#6c63ff"/>
            <path id="Pfad_41" data-name="Pfad 41" d="M441.587,193.654a46.5,46.5,0,1,1,46.5-46.5,46.5,46.5,0,0,1-46.5,46.5Zm0-91a44.5,44.5,0,1,0,44.5,44.5,44.5,44.5,0,0,0-44.5-44.5Z" transform="translate(-274.749 -100.658)" fill="#3f3d56"/>
            <circle id="Ellipse_565" data-name="Ellipse 565" cx="31.244" cy="31.244" r="31.244" transform="translate(543.586 196.176)" fill="#6c63ff"/>
            <path id="Pfad_42" data-name="Pfad 42" d="M856.908,347.593a32.244,32.244,0,1,1,32.244-32.244A32.244,32.244,0,0,1,856.908,347.593Zm0-62.488a30.244,30.244,0,1,0,30.244,30.244A30.244,30.244,0,0,0,856.908,285.1Z" transform="translate(-274.749 -100.658)" fill="#3f3d56"/>
            <path id="Path_2960" data-name="Path 2960" d="M924.251,799.342h-600a1,1,0,0,1,0-2h600a1,1,0,0,1,0,2Z" transform="translate(-274.749 -100.658)" fill="#cacaca"/>
            <g id="Group_58" data-name="Group 58">
              <path id="Path_438" data-name="Path 438" d="M882.447,758.669a19.474,19.474,0,0,1-18.806-3.313c-6.587-5.528-8.652-14.637-10.332-23.07l-4.97-24.945,10.405,7.165c7.483,5.152,15.134,10.47,20.316,17.933s7.443,17.651,3.28,25.727" transform="translate(-274.749 -100.658)" fill="#fd6584"/>
              <path id="Path_439" data-name="Path 439" d="M880.84,790.606c1.31-9.542,2.657-19.206,1.738-28.85-.816-8.565-3.429-16.93-8.749-23.789a39.574,39.574,0,0,0-10.153-9.2c-1.015-.641-1.95.968-.939,1.606a37.622,37.622,0,0,1,14.881,17.956c3.24,8.241,3.76,17.224,3.2,25.978-.338,5.294-1.053,10.553-1.774,15.806a.964.964,0,0,0,.65,1.144.936.936,0,0,0,1.144-.65Z" transform="translate(-274.749 -100.658)" fill="#f2f2f2"/>
              <path id="Path_442" data-name="Path 442" d="M871.413,775.3a14.336,14.336,0,0,1-12.491,6.447c-6.323-.3-11.595-4.713-16.34-8.9l-14.035-12.4,9.289-.444c6.68-.32,13.533-.618,19.9,1.442s12.231,7.018,13.394,13.6" transform="translate(-274.749 -100.658)" fill="#e6e6e6"/>
              <path id="Path_443" data-name="Path 443" d="M884.542,796.091c-6.3-11.156-13.618-23.555-26.685-27.518a29.78,29.78,0,0,0-11.224-1.159c-1.192.1-.894,1.94.3,1.837a27.665,27.665,0,0,1,17.912,4.739c5.051,3.438,8.983,8.217,12.311,13.286,2.039,3.1,3.865,6.341,5.691,9.573C883.426,797.882,885.132,797.136,884.542,796.091Z" transform="translate(-274.749 -100.658)" fill="#f2f2f2"/>
            </g>
            <g id="Group_222" data-name="Group 222">
              <path id="Path_2961" data-name="Path 2961" d="M387.748,771.657a14.336,14.336,0,0,0,13.961-1.64c5.074-3.784,6.976-10.391,8.57-16.516l4.707-18.121-7.949,4.826c-5.717,3.47-11.565,7.055-15.691,12.323s-6.214,12.651-3.495,18.764" transform="translate(-274.749 -100.658)" fill="#e6e6e6"/>
              <path id="Path_2962" data-name="Path 2962" d="M388.492,796.239c-1.015-12.772-1.882-27.143,6.735-37.736a29.778,29.778,0,0,1,8.658-7.238c1.044-.584,1.826,1.108.779,1.691a27.664,27.664,0,0,0-12.2,13.945c-2.265,5.675-2.852,11.835-2.777,17.9.043,3.71.342,7.418.635,11.119C390.418,797.1,388.587,797.434,388.492,796.239Z" transform="translate(-274.749 -100.658)" fill="#f2f2f2"/>
            </g>
            <circle id="Ellipse_567" data-name="Ellipse 567" cx="44.913" cy="44.913" r="44.913" transform="translate(49.952 289.547)" fill="#e4e4e4"/>
            <path id="Pfad_43" data-name="Pfad 43" d="M380.149,462.733a45.913,45.913,0,1,1,45.913-45.913A45.913,45.913,0,0,1,380.149,462.733Zm0-89.826a43.913,43.913,0,1,0,43.913,43.913,43.913,43.913,0,0,0-43.913-43.913Z" transform="translate(-274.749 -100.658)" fill="#2f2e41"/>
            <circle id="Ellipse_6" data-name="Ellipse 6" cx="8" cy="8" r="8" transform="translate(59.751 162.342)" fill="#f2f2f2"/>
            <circle id="Ellipse_7" data-name="Ellipse 7" cx="8" cy="8" r="8" transform="translate(470.751 122.342)" fill="#fd6584"/>
            <circle id="Ellipse_8" data-name="Ellipse 8" cx="8" cy="8" r="8" transform="translate(523.751 406.342)" fill="#f2f2f2"/>
            <path id="Pfad_44" data-name="Pfad 44" d="M214.063,206.857Z" fill="#f2f2f2"/>
            <path id="Pfad_45" data-name="Pfad 45" d="M448.228,302.3l.3-1.977,39.073,5.994c-3.982-3.844-17.168-15.731-29.127-16.937-14.287-1.44-20.573,4.676-21.4,12.919s4.108,15.49,18.395,16.93c12.111,1.221,27.643-8.043,32.1-10.894Z" transform="translate(-274.749 -100.658)" fill="#f2f2f2"/>
            <path id="Pfad_46" data-name="Pfad 46" d="M579.407,70.278Z" fill="#f2f2f2"/>
            <path id="Pfad_47" data-name="Pfad 47" d="M822.117,196.385l-1.206-1.6,31.544-23.823c-5.532.173-23.247,1.334-32.451,9.064-11,9.235-11,18.005-5.668,24.349S828.3,212.237,839.3,203c9.321-7.828,13.513-25.421,14.575-30.6Z" transform="translate(-274.749 -100.658)" fill="#f2f2f2"/>
            <path id="Pfad_48" data-name="Pfad 48" d="M495.935,306.755Z" fill="#f2f2f2"/>
            <path id="Pfad_49" data-name="Pfad 49" d="M747.634,425.722l-.867-1.148,22.694-17.139c-3.98.125-16.725.959-23.347,6.521-7.91,6.644-7.911,12.954-4.077,17.518s10.048,5.654,17.959-.991c6.706-5.632,9.722-18.289,10.486-22.015Z" transform="translate(-274.749 -100.658)" fill="#f2f2f2"/>
            <path id="Pfad_50" data-name="Pfad 50" d="M567.979,152.91c6.149-16.532,20.676-30.817,38.232-32.516a81.267,81.267,0,0,0-12.844,54.3c.9,7.343,2.662,15.416-1.3,21.665-2.463,3.889-6.8,6.282-11.277,7.363s-9.137,1-13.739.907l-1.03.509C562.789,187.8,561.831,169.441,567.979,152.91Z" transform="translate(-274.749 -100.658)" fill="#f0f0f0"/>
            <path id="Pfad_51" data-name="Pfad 51" d="M606.3,120.754a69.462,69.462,0,0,0-25.806,34.066,29.914,29.914,0,0,0-1.826,9.193,17.156,17.156,0,0,0,2.32,8.726c1.385,2.5,3.091,4.9,3.844,7.687a10.5,10.5,0,0,1-1.475,8.463c-1.985,3.111-4.969,5.324-7.962,7.386-3.324,2.29-6.812,4.615-8.846,8.212-.246.436-.908.028-.662-.407,3.538-6.258,10.964-8.531,15.507-13.847,2.12-2.48,3.5-5.615,2.849-8.918-.573-2.888-2.33-5.36-3.754-7.879a18.307,18.307,0,0,1-2.586-8.448,27.652,27.652,0,0,1,1.465-9.229,67.391,67.391,0,0,1,9.505-18.8,70.693,70.693,0,0,1,17.061-16.9c.409-.286.773.4.366.686Z" transform="translate(-274.749 -100.658)" fill="#fff"/>
            <path id="Pfad_52" data-name="Pfad 52" d="M581.778,150.29a10.421,10.421,0,0,1-5.206-12.45c.153-.476.9-.26.747.217a9.65,9.65,0,0,0,4.866,11.571c.447.225.037.886-.407.662Z" transform="translate(-274.749 -100.658)" fill="#fff"/>
            <path id="Pfad_53" data-name="Pfad 53" d="M580.116,171.564a20.086,20.086,0,0,0,11.393-9.192c.248-.435.91-.027.662.407A20.893,20.893,0,0,1,580.3,172.32C579.818,172.47,579.64,171.713,580.116,171.564Z" transform="translate(-274.749 -100.658)" fill="#fff"/>
            <path id="Pfad_54" data-name="Pfad 54" d="M594.084,131.283a5.9,5.9,0,0,0,5.505,1.011c.476-.156.654.6.18.756a6.61,6.61,0,0,1-6.093-1.105.4.4,0,0,1-.128-.535.391.391,0,0,1,.535-.128Z" transform="translate(-274.749 -100.658)" fill="#fff"/>
            <path id="Pfad_55" data-name="Pfad 55" d="M648.528,162.534c-.295.106-.589.211-.886.323a77.683,77.683,0,0,0-11.452,5.259c-.286.155-.574.316-.855.479a81.9,81.9,0,0,0-22.915,19.6,79.534,79.534,0,0,0-6.835,9.966c-2.793,4.821-5.437,10.27-9.538,13.783a13.646,13.646,0,0,1-1.337,1.032l-27.984-6.622c-.056-.047-.114-.088-.17-.135L565.426,206c.092-.187.191-.38.283-.567.053-.109.112-.216.164-.325.037-.072.075-.144.1-.211.012-.024.025-.048.036-.066.029-.067.064-.126.092-.187q.827-1.615,1.68-3.223c0-.006,0-.006.009-.011a116.946,116.946,0,0,1,15.527-22.877c.184-.206.369-.418.565-.621a75.9,75.9,0,0,1,9.015-8.493,67.1,67.1,0,0,1,5.525-3.945,55.793,55.793,0,0,1,15.995-6.962c11.291-2.924,23.391-2.205,33.352,3.57C648.026,162.233,648.275,162.379,648.528,162.534Z" transform="translate(-274.749 -100.658)" fill="#f0f0f0"/>
            <path id="Pfad_56" data-name="Pfad 56" d="M648.381,162.874a69.461,69.461,0,0,0-41.115,11.663,29.913,29.913,0,0,0-6.992,6.241,17.156,17.156,0,0,0-3.4,8.364c-.4,2.829-.479,5.769-1.559,8.452a10.5,10.5,0,0,1-6.273,5.869c-3.458,1.289-7.173,1.259-10.8,1.1-4.032-.173-8.218-.416-12.008,1.231-.459.2-.742-.524-.284-.724,6.593-2.867,13.891-.211,20.718-1.72,3.186-.7,6.179-2.373,7.644-5.4,1.281-2.651,1.367-5.683,1.746-8.551a18.307,18.307,0,0,1,3.021-8.3,27.653,27.653,0,0,1,6.727-6.486,67.391,67.391,0,0,1,18.906-9.284,70.693,70.693,0,0,1,23.795-3.219c.5.018.375.786-.12.768Z" transform="translate(-274.749 -100.658)" fill="#fff"/>
            <path id="Pfad_57" data-name="Pfad 57" d="M611.022,171.695a10.422,10.422,0,0,1,3.339-13.075c.409-.288.876.334.466.623a9.65,9.65,0,0,0-3.081,12.169c.222.448-.5.73-.724.284Z" transform="translate(-274.749 -100.658)" fill="#fff"/>
            <path id="Pfad_58" data-name="Pfad 58" d="M596.887,187.68a20.086,20.086,0,0,0,14.631-.48c.46-.2.743.526.284.724a20.893,20.893,0,0,1-15.225.469C596.1,188.224,596.417,187.513,596.887,187.68Z" transform="translate(-274.749 -100.658)" fill="#fff"/>
            <path id="Pfad_59" data-name="Pfad 59" d="M632.29,163.928a5.9,5.9,0,0,0,3.787,4.122c.474.163.159.874-.311.712a6.61,6.61,0,0,1-4.2-4.551.4.4,0,0,1,.22-.5A.391.391,0,0,1,632.29,163.928Z" transform="translate(-274.749 -100.658)" fill="#fff"/>
            <path id="Pfad_60" data-name="Pfad 60" d="M482.979,380.91c6.149-16.532,20.676-30.817,38.232-32.516a81.267,81.267,0,0,0-12.844,54.3c.9,7.343,2.662,15.416-1.3,21.665-2.463,3.889-6.8,6.283-11.277,7.363s-9.137,1-13.739.907l-1.03.509C477.789,415.8,476.831,397.441,482.979,380.91Z" transform="translate(-274.749 -100.658)" fill="#f0f0f0"/>
            <path id="Pfad_61" data-name="Pfad 61" d="M521.3,348.754a69.462,69.462,0,0,0-25.806,34.066,29.912,29.912,0,0,0-1.826,9.193,17.156,17.156,0,0,0,2.32,8.726c1.385,2.5,3.091,4.895,3.844,7.687a10.5,10.5,0,0,1-1.475,8.463c-1.985,3.111-4.969,5.324-7.962,7.386-3.324,2.29-6.812,4.615-8.846,8.212-.246.436-.908.028-.662-.407,3.538-6.258,10.964-8.531,15.507-13.847,2.12-2.48,3.5-5.615,2.849-8.918-.573-2.888-2.33-5.361-3.754-7.879a18.306,18.306,0,0,1-2.586-8.448,27.655,27.655,0,0,1,1.465-9.229,67.39,67.39,0,0,1,9.505-18.8,70.695,70.695,0,0,1,17.061-16.9c.409-.286.773.4.366.686Z" transform="translate(-274.749 -100.658)" fill="#fff"/>
            <path id="Pfad_62" data-name="Pfad 62" d="M496.778,378.29a10.421,10.421,0,0,1-5.206-12.45c.153-.476.9-.26.747.217a9.65,9.65,0,0,0,4.866,11.571C497.632,377.852,497.222,378.513,496.778,378.29Z" transform="translate(-274.749 -100.658)" fill="#fff"/>
            <path id="Pfad_63" data-name="Pfad 63" d="M495.116,399.564a20.086,20.086,0,0,0,11.393-9.192c.248-.435.91-.027.662.407A20.893,20.893,0,0,1,495.3,400.32c-.478.15-.656-.607-.18-.756Z" transform="translate(-274.749 -100.658)" fill="#fff"/>
            <path id="Pfad_64" data-name="Pfad 64" d="M509.084,359.283a5.9,5.9,0,0,0,5.505,1.011c.476-.156.653.6.18.756a6.61,6.61,0,0,1-6.093-1.105.4.4,0,0,1-.128-.535A.391.391,0,0,1,509.084,359.283Z" transform="translate(-274.749 -100.658)" fill="#fff"/>
            <path id="Pfad_65" data-name="Pfad 65" d="M563.528,390.534c-.295.106-.589.211-.886.323a77.688,77.688,0,0,0-11.452,5.259c-.286.155-.574.316-.855.479a81.9,81.9,0,0,0-22.915,19.6,79.536,79.536,0,0,0-6.835,9.966c-2.793,4.821-5.437,10.27-9.538,13.783a13.64,13.64,0,0,1-1.337,1.032l-27.984-6.622c-.056-.047-.114-.088-.17-.135L480.426,434c.092-.187.191-.38.283-.567.053-.109.112-.216.164-.325.037-.072.075-.144.1-.211.012-.024.025-.048.036-.066.029-.067.064-.126.092-.187q.827-1.615,1.68-3.223c0-.006,0-.006.009-.011a116.945,116.945,0,0,1,15.527-22.877c.184-.206.369-.418.565-.621a75.9,75.9,0,0,1,9.015-8.493,67.1,67.1,0,0,1,5.525-3.945,55.793,55.793,0,0,1,15.995-6.962c11.291-2.924,23.391-2.205,33.352,3.57C563.026,390.233,563.275,390.379,563.528,390.534Z" transform="translate(-274.749 -100.658)" fill="#f0f0f0"/>
            <path id="Pfad_66" data-name="Pfad 66" d="M563.381,390.874a69.461,69.461,0,0,0-41.115,11.663,29.913,29.913,0,0,0-6.992,6.241,17.156,17.156,0,0,0-3.4,8.364c-.4,2.829-.479,5.769-1.559,8.452a10.5,10.5,0,0,1-6.273,5.869c-3.458,1.289-7.173,1.259-10.8,1.1-4.032-.173-8.218-.416-12.008,1.231-.459.2-.742-.524-.284-.724,6.593-2.867,13.891-.211,20.718-1.72,3.186-.7,6.179-2.373,7.644-5.4,1.281-2.651,1.367-5.683,1.746-8.551a18.306,18.306,0,0,1,3.021-8.3,27.654,27.654,0,0,1,6.727-6.486,67.39,67.39,0,0,1,18.906-9.284,70.694,70.694,0,0,1,23.795-3.219c.5.018.375.786-.12.768Z" transform="translate(-274.749 -100.658)" fill="#fff"/>
            <path id="Pfad_67" data-name="Pfad 67" d="M526.022,399.695a10.421,10.421,0,0,1,3.339-13.075c.409-.288.876.334.466.623a9.65,9.65,0,0,0-3.081,12.169c.222.448-.5.73-.724.284Z" transform="translate(-274.749 -100.658)" fill="#fff"/>
            <path id="Pfad_68" data-name="Pfad 68" d="M511.887,415.681a20.086,20.086,0,0,0,14.631-.48c.46-.2.743.526.284.724a20.893,20.893,0,0,1-15.225.469c-.472-.169-.158-.88.311-.712Z" transform="translate(-274.749 -100.658)" fill="#fff"/>
            <path id="Pfad_69" data-name="Pfad 69" d="M547.29,391.928a5.9,5.9,0,0,0,3.787,4.122c.474.162.16.874-.311.712a6.61,6.61,0,0,1-4.2-4.551.4.4,0,0,1,.22-.5.391.391,0,0,1,.5.22Z" transform="translate(-274.749 -100.658)" fill="#fff"/>
            <path id="Pfad_70" data-name="Pfad 70" d="M831.324,504.824c-3.138-11.731-.677-25.539,8.5-33.491a55.947,55.947,0,0,0,14.432,35.6c3.431,3.763,7.639,7.592,7.908,12.678a12.506,12.506,0,0,1-3.4,8.628,37.143,37.143,0,0,1-7.35,5.985l-.376.7C842.315,526.475,834.462,516.555,831.324,504.824Z" transform="translate(-274.749 -100.658)" fill="#f0f0f0"/>
            <path id="Pfad_71" data-name="Pfad 71" d="M840.016,471.5a47.82,47.82,0,0,0-.907,29.408,20.589,20.589,0,0,0,2.639,5.888,11.811,11.811,0,0,0,4.78,3.973,24.441,24.441,0,0,1,5.222,2.783,7.232,7.232,0,0,1,2.545,5.339,18.79,18.79,0,0,1-1.526,7.32c-.953,2.61-1.984,5.306-1.692,8.136.035.343-.5.378-.534.035-.508-4.923,2.754-9.159,3.185-13.954a7.324,7.324,0,0,0-1.955-6.141c-1.473-1.393-3.445-2.08-5.248-2.926a12.6,12.6,0,0,1-4.819-3.711,19.038,19.038,0,0,1-2.856-5.764,46.393,46.393,0,0,1-2.156-14.339,48.67,48.67,0,0,1,2.842-16.284c.116-.323.594-.082.479.239Z" transform="translate(-274.749 -100.658)" fill="#fff"/>
            <path id="Pfad_72" data-name="Pfad 72" d="M838.025,497.854a7.175,7.175,0,0,1-7.884-4.914c-.1-.328.4-.5.506-.176a6.643,6.643,0,0,0,7.344,4.556c.34-.052.374.482.035.534Z" transform="translate(-274.749 -100.658)" fill="#fff"/>
            <path id="Pfad_73" data-name="Pfad 73" d="M845.572,510.458a13.828,13.828,0,0,0,2.731-9.7c-.034-.343.5-.378.534-.035a14.384,14.384,0,0,1-2.863,10.088C845.766,511.085,845.364,510.731,845.572,510.458Z" transform="translate(-274.749 -100.658)" fill="#fff"/>
            <path id="Pfad_74" data-name="Pfad 74" d="M837.357,482.28a4.061,4.061,0,0,0,3.493-1.627c.205-.277.607.077.4.353a4.551,4.551,0,0,1-3.861,1.808.277.277,0,0,1-.285-.249.269.269,0,0,1,.249-.285Z" transform="translate(-274.749 -100.658)" fill="#fff"/>
            <path id="Pfad_75" data-name="Pfad 75" d="M880.374,478.121c-.123.177-.247.353-.368.534a53.486,53.486,0,0,0-4.332,7.517c-.1.2-.2.406-.289.609a56.383,56.383,0,0,0-5.052,20.134,54.759,54.759,0,0,0,.135,8.318c.354,3.819,1.041,7.932.139,11.538a9.38,9.38,0,0,1-.339,1.112l-18.348,7.437c-.05,0-.1,0-.149-.008l-.719.33c-.023-.142-.044-.289-.067-.431-.014-.082-.023-.166-.037-.248-.008-.055-.016-.111-.026-.16,0-.018-.005-.037-.006-.051-.01-.049-.015-.1-.023-.142q-.179-1.236-.342-2.479s0,0,0-.01a80.514,80.514,0,0,1-.4-19.03c.021-.189.04-.382.07-.574a52.243,52.243,0,0,1,1.675-8.36,46.174,46.174,0,0,1,1.529-4.416,38.41,38.41,0,0,1,6.2-10.283c5.172-6.142,12.251-10.561,20.144-11.289C879.972,478.153,880.17,478.136,880.374,478.121Z" transform="translate(-274.749 -100.658)" fill="#f0f0f0"/>
            <path id="Pfad_76" data-name="Pfad 76" d="M880.427,478.371A47.82,47.82,0,0,0,862,501.305a20.6,20.6,0,0,0-1.437,6.29,11.811,11.811,0,0,0,1.424,6.051,24.444,24.444,0,0,1,2.494,5.366,7.232,7.232,0,0,1-1.182,5.8,18.79,18.79,0,0,1-5.625,4.926c-2.332,1.51-4.779,3.042-6.249,5.477-.178.3-.626,0-.448-.293,2.558-4.237,7.713-5.655,10.944-9.224a7.324,7.324,0,0,0,2.137-6.081c-.338-2-1.5-3.735-2.428-5.5a12.6,12.6,0,0,1-1.613-5.864,19.041,19.041,0,0,1,1.19-6.322,46.394,46.394,0,0,1,6.911-12.747,48.665,48.665,0,0,1,12.074-11.291c.287-.188.524.292.239.479Z" transform="translate(-274.749 -100.658)" fill="#fff"/>
            <path id="Pfad_77" data-name="Pfad 77" d="M862.972,498.213a7.175,7.175,0,0,1-3.337-8.67c.115-.325.625-.161.51.164a6.643,6.643,0,0,0,3.121,8.059C863.568,497.929,863.273,498.376,862.972,498.213Z" transform="translate(-274.749 -100.658)" fill="#fff"/>
            <path id="Pfad_78" data-name="Pfad 78" d="M861.409,512.82a13.828,13.828,0,0,0,8.021-6.1c.179-.294.627,0,.448.293a14.383,14.383,0,0,1-8.359,6.332c-.332.094-.44-.431-.109-.524Z" transform="translate(-274.749 -100.658)" fill="#fff"/>
            <path id="Pfad_79" data-name="Pfad 79" d="M871.815,485.376a4.061,4.061,0,0,0,3.769.8c.331-.1.438.427.109.524a4.551,4.551,0,0,1-4.171-.881.277.277,0,0,1-.077-.371.269.269,0,0,1,.371-.077Z" transform="translate(-274.749 -100.658)" fill="#fff"/>
          </g>
        </svg>

        <div id="loader">Loading...</div>
        {<LatestProjects />}
        <div class="divAllProjects" id="divAllProjects">
          <button id="linkAllProjects"><Link to="/projects">Visit all projects ...</Link></button>
        </div>
      </section>
    );
  }
}

export default SecondSite;
