import React from 'react';
import {Link } from "react-router-dom";


class FilterButtonCategory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      render: true,
      language: null
    }
  }
  delete() {
    this.setState({
      render: false
    })
  }
  componentDidMount() {
    setInterval(() => {
      this.setState({
        render: (window.location.pathname.includes("javascript") || window.location.pathname.includes("css")) ? true : false,
        language: (window.location.pathname.includes("javascript")) ? "Javascript" : (window.location.pathname.includes("css")) ? "CSS" : "error"
      })
    }, 200)
  }
  render() {
    const ConditionalLink = ({ firstIf, secondIf, thirdIf, fourthIf, fifthIf }) => (window.location.pathname.includes("/javascript/onestar") || window.location.pathname.includes("/css/onestar")) ? <Link class="removeCategoryLink" to={firstIf}><button id="removeCategory" onClick={(e) => this.delete(e)}></button></Link> : (window.location.pathname.includes("/javascript/twostar") || window.location.pathname.includes("/css/twostar")) ? <Link class="removeCategoryLink" to={secondIf}><button id="removeCategory" onClick={(e) => this.delete(e)}></button></Link> : (window.location.pathname.includes("/javascript/threestar") || window.location.pathname.includes("/css/threestar")) ? <Link class="removeCategoryLink" to={thirdIf}><button id="removeCategory" onClick={(e) => this.delete(e)}></button></Link> : (window.location.pathname.includes("/javascript/fourstar") || window.location.pathname.includes("/css/fourstar")) ? <Link class="removeCategoryLink" to={fourthIf}><button id="removeCategory" onClick={(e) => this.delete(e)}></button></Link> : (window.location.pathname.includes("/javascript/fivestar") || window.location.pathname.includes("/css/fivestar")) ? <Link class="removeCategoryLink" to={fifthIf}><button id="removeCategory" onClick={(e) => this.delete(e)}></button></Link> : <Link class="removeCategoryLink" to="/projects"><button id="removeCategory" onClick={(e) => this.delete(e)}></button></Link>;
    if (this.state.render) {
      return(
        <div class="categoryButton">
          <p>Language:</p>
          <p id="categoryState">{this.state.language}</p>
          <ConditionalLink firstIf="/projects/onestar" secondIf="/projects/twostar" thirdIf="/projects/threestar" fourthIf="/projects/fourstar" fifthIf="/projects/fivestar"></ConditionalLink>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default FilterButtonCategory;
