document.addEventListener('DOMContentLoaded', () => {
  if (window.location.pathname.includes("/home")) {
    var left = document.querySelector(".left");
    var right = document.querySelector(".right");
    var container = document.querySelector(".containeroption");

    left.addEventListener("mouseenter", () => {
      container.classList.add("hover-left");
    	console.log("Left");
    });

    left.addEventListener("mouseleave", () => {
      container.classList.remove("hover-left");
    });

    right.addEventListener("mouseenter", () => {
      container.classList.add("hover-right");
    	console.log("Right");
    });

    right.addEventListener("mouseleave", () => {
      container.classList.remove("hover-right");
    });
  } else {
    return null;
  }
})
